'use strict';
var Actor = require('../actor');
var WebWorkify = require('webworkify');
module.exports = Dispatcher;
function Dispatcher(length, parent) {
    this.actors = [];
    this.currentActor = 0;
    for (var i = 0; i < length; i++) {
        var worker = new WebWorkify(require('../../source/worker'));
        var actor = new Actor(worker, parent);
        actor.name = 'Worker ' + i;
        this.actors.push(actor);
    }
}
Dispatcher.prototype = {
    broadcast: function (type, data) {
        for (var i = 0; i < this.actors.length; i++) {
            this.actors[i].send(type, data);
        }
    },
    send: function (type, data, callback, targetID, buffers) {
        if (typeof targetID !== 'number' || isNaN(targetID)) {
            targetID = this.currentActor = (this.currentActor + 1) % this.actors.length;
        }
        this.actors[targetID].send(type, data, callback, buffers);
        return targetID;
    },
    remove: function () {
        for (var i = 0; i < this.actors.length; i++) {
            this.actors[i].target.terminate();
        }
        this.actors = [];
    }
};