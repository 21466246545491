'use strict';
module.exports = Popup;
var util = require('../util/util');
var Evented = require('../util/evented');
var DOM = require('../util/dom');
var LngLat = require('../geo/lng_lat');
function Popup(options) {
    util.setOptions(this, options);
    util.bindAll([
        '_update',
        '_onClickClose'
    ], this);
}
Popup.prototype = util.inherit(Evented, {
    options: {
        closeButton: true,
        closeOnClick: true
    },
    addTo: function (map) {
        this._map = map;
        this._map.on('move', this._update);
        if (this.options.closeOnClick) {
            this._map.on('click', this._onClickClose);
        }
        this._update();
        return this;
    },
    remove: function () {
        if (this._content && this._content.parentNode) {
            this._content.parentNode.removeChild(this._content);
        }
        if (this._container) {
            this._container.parentNode.removeChild(this._container);
            delete this._container;
        }
        if (this._map) {
            this._map.off('move', this._update);
            this._map.off('click', this._onClickClose);
            delete this._map;
        }
        return this;
    },
    getLngLat: function () {
        return this._lngLat;
    },
    setLngLat: function (lnglat) {
        this._lngLat = LngLat.convert(lnglat);
        this._update();
        return this;
    },
    setText: function (text) {
        return this.setDOMContent(document.createTextNode(text));
    },
    setHTML: function (html) {
        var frag = document.createDocumentFragment();
        var temp = document.createElement('body'), child;
        temp.innerHTML = html;
        while (true) {
            child = temp.firstChild;
            if (!child)
                break;
            frag.appendChild(child);
        }
        return this.setDOMContent(frag);
    },
    setDOMContent: function (htmlNode) {
        this._createContent();
        this._content.appendChild(htmlNode);
        this._update();
        return this;
    },
    _createContent: function () {
        if (this._content && this._content.parentNode) {
            this._content.parentNode.removeChild(this._content);
        }
        this._content = DOM.create('div', 'mapboxgl-popup-content', this._container);
        if (this.options.closeButton) {
            this._closeButton = DOM.create('button', 'mapboxgl-popup-close-button', this._content);
            this._closeButton.innerHTML = '&#215;';
            this._closeButton.addEventListener('click', this._onClickClose);
        }
    },
    _update: function () {
        if (!this._map || !this._lngLat || !this._content) {
            return;
        }
        if (!this._container) {
            this._container = DOM.create('div', 'mapboxgl-popup', this._map.getContainer());
            this._tip = DOM.create('div', 'mapboxgl-popup-tip', this._container);
            this._container.appendChild(this._content);
        }
        var pos = this._map.project(this._lngLat).round(), anchor = this.options.anchor;
        if (!anchor) {
            var width = this._container.offsetWidth, height = this._container.offsetHeight;
            if (pos.y < height) {
                anchor = ['top'];
            } else if (pos.y > this._map.transform.height - height) {
                anchor = ['bottom'];
            } else {
                anchor = [];
            }
            if (pos.x < width / 2) {
                anchor.push('left');
            } else if (pos.x > this._map.transform.width - width / 2) {
                anchor.push('right');
            }
            if (anchor.length === 0) {
                anchor = 'bottom';
            } else {
                anchor = anchor.join('-');
            }
        }
        var anchorTranslate = {
            'top': 'translate(-50%,0)',
            'top-left': 'translate(0,0)',
            'top-right': 'translate(-100%,0)',
            'bottom': 'translate(-50%,-100%)',
            'bottom-left': 'translate(0,-100%)',
            'bottom-right': 'translate(-100%,-100%)',
            'left': 'translate(0,-50%)',
            'right': 'translate(-100%,-50%)'
        };
        var classList = this._container.classList;
        for (var key in anchorTranslate) {
            classList.remove('mapboxgl-popup-anchor-' + key);
        }
        classList.add('mapboxgl-popup-anchor-' + anchor);
        DOM.setTransform(this._container, anchorTranslate[anchor] + ' translate(' + pos.x + 'px,' + pos.y + 'px)');
    },
    _onClickClose: function () {
        this.remove();
    }
});