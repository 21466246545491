'use strict';
var featureFilter = require('feature-filter');
var Buffer = require('./buffer');
var util = require('../util/util');
var StructArrayType = require('../util/struct_array');
var VertexArrayObject = require('../render/vertex_array_object');
module.exports = Bucket;
Bucket.create = function (options) {
    var Classes = {
        fill: require('./bucket/fill_bucket'),
        line: require('./bucket/line_bucket'),
        circle: require('./bucket/circle_bucket'),
        symbol: require('./bucket/symbol_bucket')
    };
    return new Classes[options.layer.type](options);
};
Bucket.EXTENT = 8192;
Bucket.MAX_VERTEX_ARRAY_LENGTH = Math.pow(2, 16) - 1;
function Bucket(options) {
    this.zoom = options.zoom;
    this.overscaling = options.overscaling;
    this.layer = options.layer;
    this.childLayers = options.childLayers;
    this.type = this.layer.type;
    this.features = [];
    this.id = this.layer.id;
    this.index = options.index;
    this.sourceLayer = this.layer.sourceLayer;
    this.sourceLayerIndex = options.sourceLayerIndex;
    this.minZoom = this.layer.minzoom;
    this.maxZoom = this.layer.maxzoom;
    this.paintAttributes = createPaintAttributes(this);
    if (options.arrays) {
        var childLayers = this.childLayers;
        this.bufferGroups = util.mapObject(options.arrays, function (programArrayGroups, programName) {
            return programArrayGroups.map(function (programArrayGroup) {
                var group = util.mapObject(programArrayGroup, function (arrays, layoutOrPaint) {
                    return util.mapObject(arrays, function (array, name) {
                        var arrayType = options.arrayTypes[programName][layoutOrPaint][name];
                        var type = arrayType.members.length && arrayType.members[0].name === 'vertices' ? Buffer.BufferType.ELEMENT : Buffer.BufferType.VERTEX;
                        return new Buffer(array, arrayType, type);
                    });
                });
                group.vaos = {};
                if (group.layout.element2)
                    group.secondVaos = {};
                for (var l = 0; l < childLayers.length; l++) {
                    var layerName = childLayers[l].id;
                    group.vaos[layerName] = new VertexArrayObject();
                    if (group.layout.element2)
                        group.secondVaos[layerName] = new VertexArrayObject();
                }
                return group;
            });
        });
    }
}
Bucket.prototype.populateBuffers = function () {
    this.createArrays();
    this.recalculateStyleLayers();
    for (var i = 0; i < this.features.length; i++) {
        this.addFeature(this.features[i]);
    }
    this.trimArrays();
};
Bucket.prototype.prepareArrayGroup = function (programName, numVertices) {
    var groups = this.arrayGroups[programName];
    var currentGroup = groups.length && groups[groups.length - 1];
    if (!currentGroup || currentGroup.layout.vertex.length + numVertices > Bucket.MAX_VERTEX_ARRAY_LENGTH) {
        var arrayTypes = this.arrayTypes[programName];
        var VertexArrayType = arrayTypes.layout.vertex;
        var ElementArrayType = arrayTypes.layout.element;
        var ElementArrayType2 = arrayTypes.layout.element2;
        currentGroup = {
            index: groups.length,
            layout: {},
            paint: {}
        };
        currentGroup.layout.vertex = new VertexArrayType();
        if (ElementArrayType)
            currentGroup.layout.element = new ElementArrayType();
        if (ElementArrayType2)
            currentGroup.layout.element2 = new ElementArrayType2();
        for (var i = 0; i < this.childLayers.length; i++) {
            var layerName = this.childLayers[i].id;
            var PaintVertexArrayType = arrayTypes.paint[layerName];
            currentGroup.paint[layerName] = new PaintVertexArrayType();
        }
        groups.push(currentGroup);
    }
    return currentGroup;
};
Bucket.prototype.createArrays = function () {
    this.arrayGroups = {};
    this.arrayTypes = {};
    for (var programName in this.programInterfaces) {
        var programInterface = this.programInterfaces[programName];
        var programArrayTypes = this.arrayTypes[programName] = {
            layout: {},
            paint: {}
        };
        this.arrayGroups[programName] = [];
        if (programInterface.vertexBuffer) {
            var VertexArrayType = new StructArrayType({
                members: this.programInterfaces[programName].layoutAttributes,
                alignment: Buffer.VERTEX_ATTRIBUTE_ALIGNMENT
            });
            programArrayTypes.layout.vertex = VertexArrayType;
            var layerPaintAttributes = this.paintAttributes[programName];
            for (var layerName in layerPaintAttributes) {
                var PaintVertexArrayType = new StructArrayType({
                    members: layerPaintAttributes[layerName].attributes,
                    alignment: Buffer.VERTEX_ATTRIBUTE_ALIGNMENT
                });
                programArrayTypes.paint[layerName] = PaintVertexArrayType;
            }
        }
        if (programInterface.elementBuffer) {
            var ElementArrayType = createElementBufferType(programInterface.elementBufferComponents);
            programArrayTypes.layout.element = ElementArrayType;
        }
        if (programInterface.elementBuffer2) {
            var ElementArrayType2 = createElementBufferType(programInterface.elementBuffer2Components);
            programArrayTypes.layout.element2 = ElementArrayType2;
        }
    }
};
Bucket.prototype.destroy = function (gl) {
    for (var programName in this.bufferGroups) {
        var programBufferGroups = this.bufferGroups[programName];
        for (var i = 0; i < programBufferGroups.length; i++) {
            var programBuffers = programBufferGroups[i];
            for (var paintBuffer in programBuffers.paint) {
                programBuffers.paint[paintBuffer].destroy(gl);
            }
            for (var layoutBuffer in programBuffers.layout) {
                programBuffers.layout[layoutBuffer].destroy(gl);
            }
            for (var j in programBuffers.vaos) {
                programBuffers.vaos[j].destroy(gl);
            }
            for (var k in programBuffers.secondVaos) {
                programBuffers.secondVaos[k].destroy(gl);
            }
        }
    }
};
Bucket.prototype.trimArrays = function () {
    for (var programName in this.arrayGroups) {
        var programArrays = this.arrayGroups[programName];
        for (var paintArray in programArrays.paint) {
            programArrays.paint[paintArray].trim();
        }
        for (var layoutArray in programArrays.layout) {
            programArrays.layout[layoutArray].trim();
        }
    }
};
Bucket.prototype.setUniforms = function (gl, programName, program, layer, globalProperties) {
    var uniforms = this.paintAttributes[programName][layer.id].uniforms;
    for (var i = 0; i < uniforms.length; i++) {
        var uniform = uniforms[i];
        var uniformLocation = program[uniform.name];
        gl['uniform' + uniform.components + 'fv'](uniformLocation, uniform.getValue(layer, globalProperties));
    }
};
Bucket.prototype.serialize = function () {
    return {
        layerId: this.layer.id,
        zoom: this.zoom,
        arrays: util.mapObject(this.arrayGroups, function (programArrayGroups) {
            return programArrayGroups.map(function (arrayGroup) {
                return util.mapObject(arrayGroup, function (arrays) {
                    return util.mapObject(arrays, function (array) {
                        return array.serialize();
                    });
                });
            });
        }),
        arrayTypes: util.mapObject(this.arrayTypes, function (programArrayTypes) {
            return util.mapObject(programArrayTypes, function (arrayTypes) {
                return util.mapObject(arrayTypes, function (arrayType) {
                    return arrayType.serialize();
                });
            });
        }),
        childLayerIds: this.childLayers.map(function (layer) {
            return layer.id;
        })
    };
};
Bucket.prototype.createFilter = function () {
    if (!this.filter) {
        this.filter = featureFilter(this.layer.filter);
    }
};
var FAKE_ZOOM_HISTORY = {
    lastIntegerZoom: Infinity,
    lastIntegerZoomTime: 0,
    lastZoom: 0
};
Bucket.prototype.recalculateStyleLayers = function () {
    for (var i = 0; i < this.childLayers.length; i++) {
        this.childLayers[i].recalculate(this.zoom, FAKE_ZOOM_HISTORY);
    }
};
Bucket.prototype.populatePaintArrays = function (interfaceName, globalProperties, featureProperties, startGroup, startIndex) {
    for (var l = 0; l < this.childLayers.length; l++) {
        var layer = this.childLayers[l];
        var groups = this.arrayGroups[interfaceName];
        for (var g = startGroup.index; g < groups.length; g++) {
            var group = groups[g];
            var length = group.layout.vertex.length;
            var vertexArray = group.paint[layer.id];
            vertexArray.resize(length);
            var attributes = this.paintAttributes[interfaceName][layer.id].attributes;
            for (var m = 0; m < attributes.length; m++) {
                var attribute = attributes[m];
                var value = attribute.getValue(layer, globalProperties, featureProperties);
                var multiplier = attribute.multiplier || 1;
                var components = attribute.components || 1;
                var start = g === startGroup.index ? startIndex : 0;
                for (var i = start; i < length; i++) {
                    var vertex = vertexArray.get(i);
                    for (var c = 0; c < components; c++) {
                        var memberName = components > 1 ? attribute.name + c : attribute.name;
                        vertex[memberName] = value[c] * multiplier;
                    }
                }
            }
        }
    }
};
function createElementBufferType(components) {
    return new StructArrayType({
        members: [{
                type: Buffer.ELEMENT_ATTRIBUTE_TYPE,
                name: 'vertices',
                components: components || 3
            }]
    });
}
function createPaintAttributes(bucket) {
    var attributes = {};
    for (var interfaceName in bucket.programInterfaces) {
        var layerPaintAttributes = attributes[interfaceName] = {};
        for (var c = 0; c < bucket.childLayers.length; c++) {
            var childLayer = bucket.childLayers[c];
            layerPaintAttributes[childLayer.id] = {
                attributes: [],
                uniforms: [],
                defines: [],
                vertexPragmas: {
                    define: {},
                    initialize: {}
                },
                fragmentPragmas: {
                    define: {},
                    initialize: {}
                }
            };
        }
        var interface_ = bucket.programInterfaces[interfaceName];
        if (!interface_.paintAttributes)
            continue;
        var attributePrecision = '{precision}';
        var attributeType = '{type}';
        for (var i = 0; i < interface_.paintAttributes.length; i++) {
            var attribute = interface_.paintAttributes[i];
            attribute.multiplier = attribute.multiplier || 1;
            for (var j = 0; j < bucket.childLayers.length; j++) {
                var layer = bucket.childLayers[j];
                var paintAttributes = layerPaintAttributes[layer.id];
                var attributeInputName = attribute.name;
                var attributeInnerName = attribute.name.slice(2);
                var attributeVaryingDefinition;
                paintAttributes.fragmentPragmas.initialize[attributeInnerName] = '';
                if (layer.isPaintValueFeatureConstant(attribute.paintProperty)) {
                    paintAttributes.uniforms.push(attribute);
                    paintAttributes.fragmentPragmas.define[attributeInnerName] = paintAttributes.vertexPragmas.define[attributeInnerName] = [
                        'uniform',
                        attributePrecision,
                        attributeType,
                        attributeInputName
                    ].join(' ') + ';';
                    paintAttributes.fragmentPragmas.initialize[attributeInnerName] = paintAttributes.vertexPragmas.initialize[attributeInnerName] = [
                        attributePrecision,
                        attributeType,
                        attributeInnerName,
                        '=',
                        attributeInputName
                    ].join(' ') + ';\n';
                } else if (layer.isPaintValueZoomConstant(attribute.paintProperty)) {
                    paintAttributes.attributes.push(util.extend({}, attribute, { name: attributeInputName }));
                    attributeVaryingDefinition = [
                        'varying',
                        attributePrecision,
                        attributeType,
                        attributeInnerName
                    ].join(' ') + ';\n';
                    var attributeAttributeDefinition = [
                        paintAttributes.fragmentPragmas.define[attributeInnerName],
                        'attribute',
                        attributePrecision,
                        attributeType,
                        attributeInputName
                    ].join(' ') + ';\n';
                    paintAttributes.fragmentPragmas.define[attributeInnerName] = attributeVaryingDefinition;
                    paintAttributes.vertexPragmas.define[attributeInnerName] = attributeVaryingDefinition + attributeAttributeDefinition;
                    paintAttributes.vertexPragmas.initialize[attributeInnerName] = [
                        attributeInnerName,
                        '=',
                        attributeInputName,
                        '/',
                        attribute.multiplier.toFixed(1)
                    ].join(' ') + ';\n';
                } else {
                    var tName = 'u_' + attributeInputName.slice(2) + '_t';
                    var zoomLevels = layer.getPaintValueStopZoomLevels(attribute.paintProperty);
                    var numStops = 0;
                    while (numStops < zoomLevels.length && zoomLevels[numStops] < bucket.zoom)
                        numStops++;
                    var stopOffset = Math.max(0, Math.min(zoomLevels.length - 4, numStops - 2));
                    var fourZoomLevels = [];
                    for (var s = 0; s < 4; s++) {
                        fourZoomLevels.push(zoomLevels[Math.min(stopOffset + s, zoomLevels.length - 1)]);
                    }
                    attributeVaryingDefinition = [
                        'varying',
                        attributePrecision,
                        attributeType,
                        attributeInnerName
                    ].join(' ') + ';\n';
                    paintAttributes.vertexPragmas.define[attributeInnerName] = attributeVaryingDefinition + [
                        'uniform',
                        'lowp',
                        'float',
                        tName
                    ].join(' ') + ';\n';
                    paintAttributes.fragmentPragmas.define[attributeInnerName] = attributeVaryingDefinition;
                    paintAttributes.uniforms.push(util.extend({}, attribute, {
                        name: tName,
                        getValue: createGetUniform(attribute, stopOffset),
                        components: 1
                    }));
                    var components = attribute.components;
                    if (components === 1) {
                        paintAttributes.attributes.push(util.extend({}, attribute, {
                            getValue: createFunctionGetValue(attribute, fourZoomLevels),
                            isFunction: true,
                            components: components * 4
                        }));
                        paintAttributes.vertexPragmas.define[attributeInnerName] += [
                            'attribute',
                            attributePrecision,
                            'vec4',
                            attributeInputName
                        ].join(' ') + ';\n';
                        paintAttributes.vertexPragmas.initialize[attributeInnerName] = [
                            attributeInnerName,
                            '=',
                            'evaluate_zoom_function_1(' + attributeInputName + ', ' + tName + ')',
                            '/',
                            attribute.multiplier.toFixed(1)
                        ].join(' ') + ';\n';
                    } else {
                        var attributeInputNames = [];
                        for (var k = 0; k < 4; k++) {
                            attributeInputNames.push(attributeInputName + k);
                            paintAttributes.attributes.push(util.extend({}, attribute, {
                                getValue: createFunctionGetValue(attribute, [fourZoomLevels[k]]),
                                isFunction: true,
                                name: attributeInputName + k
                            }));
                            paintAttributes.vertexPragmas.define[attributeInnerName] += [
                                'attribute',
                                attributePrecision,
                                attributeType,
                                attributeInputName + k
                            ].join(' ') + ';\n';
                        }
                        paintAttributes.vertexPragmas.initialize[attributeInnerName] = [
                            attributeInnerName,
                            ' = ',
                            'evaluate_zoom_function_4(' + attributeInputNames.join(', ') + ', ' + tName + ')',
                            '/',
                            attribute.multiplier.toFixed(1)
                        ].join(' ') + ';\n';
                    }
                }
            }
        }
    }
    return attributes;
}
function createFunctionGetValue(attribute, stopZoomLevels) {
    return function (layer, globalProperties, featureProperties) {
        if (stopZoomLevels.length === 1) {
            return attribute.getValue(layer, util.extend({}, globalProperties, { zoom: stopZoomLevels[0] }), featureProperties);
        } else {
            var values = [];
            for (var z = 0; z < stopZoomLevels.length; z++) {
                var stopZoomLevel = stopZoomLevels[z];
                values.push(attribute.getValue(layer, util.extend({}, globalProperties, { zoom: stopZoomLevel }), featureProperties)[0]);
            }
            return values;
        }
    };
}
function createGetUniform(attribute, stopOffset) {
    return function (layer, globalProperties) {
        var stopInterp = layer.getPaintInterpolationT(attribute.paintProperty, globalProperties.zoom);
        return [Math.max(0, Math.min(4, stopInterp - stopOffset))];
    };
}