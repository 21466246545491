'use strict';
var resolveTokens = require('../util/token');
module.exports = resolveText;
function resolveText(features, layoutProperties, codepoints) {
    var textFeatures = [];
    for (var i = 0, fl = features.length; i < fl; i++) {
        var text = resolveTokens(features[i].properties, layoutProperties['text-field']);
        if (!text) {
            textFeatures[i] = null;
            continue;
        }
        text = text.toString();
        var transform = layoutProperties['text-transform'];
        if (transform === 'uppercase') {
            text = text.toLocaleUpperCase();
        } else if (transform === 'lowercase') {
            text = text.toLocaleLowerCase();
        }
        for (var j = 0; j < text.length; j++) {
            codepoints[text.charCodeAt(j)] = true;
        }
        textFeatures[i] = text;
    }
    return textFeatures;
}