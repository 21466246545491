'use strict';
var util = require('../util');
var isSupported = require('mapbox-gl-supported');
module.exports = Canvas;
function Canvas(parent, container) {
    this.canvas = document.createElement('canvas');
    if (parent && container) {
        this.canvas.style.position = 'absolute';
        this.canvas.classList.add('mapboxgl-canvas');
        this.canvas.addEventListener('webglcontextlost', parent._contextLost.bind(parent), false);
        this.canvas.addEventListener('webglcontextrestored', parent._contextRestored.bind(parent), false);
        this.canvas.setAttribute('tabindex', 0);
        container.appendChild(this.canvas);
    }
}
Canvas.prototype.resize = function (width, height) {
    var pixelRatio = window.devicePixelRatio || 1;
    this.canvas.width = pixelRatio * width;
    this.canvas.height = pixelRatio * height;
    this.canvas.style.width = width + 'px';
    this.canvas.style.height = height + 'px';
};
Canvas.prototype.getWebGLContext = function (attributes) {
    attributes = util.extend({}, attributes, isSupported.webGLContextAttributes);
    return this.canvas.getContext('webgl', attributes) || this.canvas.getContext('experimental-webgl', attributes);
};
Canvas.prototype.getElement = function () {
    return this.canvas;
};