'use strict';
var DOM = require('../../util/dom'), browser = require('../../util/browser'), util = require('../../util/util');
module.exports = ScrollZoomHandler;
var ua = typeof navigator !== 'undefined' ? navigator.userAgent.toLowerCase() : '', firefox = ua.indexOf('firefox') !== -1, safari = ua.indexOf('safari') !== -1 && ua.indexOf('chrom') === -1;
function ScrollZoomHandler(map) {
    this._map = map;
    this._el = map.getCanvasContainer();
    util.bindHandlers(this);
}
ScrollZoomHandler.prototype = {
    _enabled: false,
    isEnabled: function () {
        return this._enabled;
    },
    enable: function () {
        if (this.isEnabled())
            return;
        this._el.addEventListener('wheel', this._onWheel, false);
        this._el.addEventListener('mousewheel', this._onWheel, false);
        this._enabled = true;
    },
    disable: function () {
        if (!this.isEnabled())
            return;
        this._el.removeEventListener('wheel', this._onWheel);
        this._el.removeEventListener('mousewheel', this._onWheel);
        this._enabled = false;
    },
    _onWheel: function (e) {
        var value;
        if (e.type === 'wheel') {
            value = e.deltaY;
            if (firefox && e.deltaMode === window.WheelEvent.DOM_DELTA_PIXEL)
                value /= browser.devicePixelRatio;
            if (e.deltaMode === window.WheelEvent.DOM_DELTA_LINE)
                value *= 40;
        } else if (e.type === 'mousewheel') {
            value = -e.wheelDeltaY;
            if (safari)
                value = value / 3;
        }
        var now = browser.now(), timeDelta = now - (this._time || 0);
        this._pos = DOM.mousePos(this._el, e);
        this._time = now;
        if (value !== 0 && value % 4.000244140625 === 0) {
            this._type = 'wheel';
            value = Math.floor(value / 4);
        } else if (value !== 0 && Math.abs(value) < 4) {
            this._type = 'trackpad';
        } else if (timeDelta > 400) {
            this._type = null;
            this._lastValue = value;
            this._timeout = setTimeout(this._onTimeout, 40);
        } else if (!this._type) {
            this._type = Math.abs(timeDelta * value) < 200 ? 'trackpad' : 'wheel';
            if (this._timeout) {
                clearTimeout(this._timeout);
                this._timeout = null;
                value += this._lastValue;
            }
        }
        if (e.shiftKey && value)
            value = value / 4;
        if (this._type)
            this._zoom(-value, e);
        e.preventDefault();
    },
    _onTimeout: function () {
        this._type = 'wheel';
        this._zoom(-this._lastValue);
    },
    _zoom: function (delta, e) {
        if (delta === 0)
            return;
        var map = this._map;
        var scale = 2 / (1 + Math.exp(-Math.abs(delta / 100)));
        if (delta < 0 && scale !== 0)
            scale = 1 / scale;
        var fromScale = map.ease ? map.ease.to : map.transform.scale, targetZoom = map.transform.scaleZoom(fromScale * scale);
        map.zoomTo(targetZoom, {
            duration: 0,
            around: map.unproject(this._pos),
            delayEndEvents: 200
        }, { originalEvent: e });
    }
};