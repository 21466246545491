'use strict';
module.exports = VertexArrayObject;
function VertexArrayObject() {
    this.boundProgram = null;
    this.boundVertexBuffer = null;
    this.boundVertexBuffer2 = null;
    this.boundElementBuffer = null;
    this.vao = null;
}
VertexArrayObject.prototype.bind = function (gl, program, vertexBuffer, elementBuffer, vertexBuffer2) {
    if (gl.extVertexArrayObject === undefined) {
        gl.extVertexArrayObject = gl.getExtension('OES_vertex_array_object');
    }
    var isFreshBindRequired = !this.vao || this.boundProgram !== program || this.boundVertexBuffer !== vertexBuffer || this.boundVertexBuffer2 !== vertexBuffer2 || this.boundElementBuffer !== elementBuffer;
    if (!gl.extVertexArrayObject || isFreshBindRequired) {
        this.freshBind(gl, program, vertexBuffer, elementBuffer, vertexBuffer2);
    } else {
        gl.extVertexArrayObject.bindVertexArrayOES(this.vao);
    }
};
VertexArrayObject.prototype.freshBind = function (gl, program, vertexBuffer, elementBuffer, vertexBuffer2) {
    var numPrevAttributes;
    var numNextAttributes = program.numAttributes;
    if (gl.extVertexArrayObject) {
        if (this.vao)
            this.destroy(gl);
        this.vao = gl.extVertexArrayObject.createVertexArrayOES();
        gl.extVertexArrayObject.bindVertexArrayOES(this.vao);
        numPrevAttributes = 0;
        this.boundProgram = program;
        this.boundVertexBuffer = vertexBuffer;
        this.boundVertexBuffer2 = vertexBuffer2;
        this.boundElementBuffer = elementBuffer;
    } else {
        numPrevAttributes = gl.currentNumAttributes || 0;
        for (var i = numNextAttributes; i < numPrevAttributes; i++) {
            gl.disableVertexAttribArray(i);
        }
    }
    for (var j = numPrevAttributes; j < numNextAttributes; j++) {
        gl.enableVertexAttribArray(j);
    }
    vertexBuffer.bind(gl);
    vertexBuffer.setVertexAttribPointers(gl, program);
    if (vertexBuffer2) {
        vertexBuffer2.bind(gl);
        vertexBuffer2.setVertexAttribPointers(gl, program);
    }
    if (elementBuffer) {
        elementBuffer.bind(gl);
    }
    gl.currentNumAttributes = numNextAttributes;
};
VertexArrayObject.prototype.unbind = function (gl) {
    var ext = gl.extVertexArrayObject;
    if (ext) {
        ext.bindVertexArrayOES(null);
    }
};
VertexArrayObject.prototype.destroy = function (gl) {
    var ext = gl.extVertexArrayObject;
    if (ext && this.vao) {
        ext.deleteVertexArrayOES(this.vao);
        this.vao = null;
    }
};