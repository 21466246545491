'use strict';
module.exports = checkMaxAngle;
function checkMaxAngle(line, anchor, labelLength, windowSize, maxAngle) {
    if (anchor.segment === undefined)
        return true;
    var p = anchor;
    var index = anchor.segment + 1;
    var anchorDistance = 0;
    while (anchorDistance > -labelLength / 2) {
        index--;
        if (index < 0)
            return false;
        anchorDistance -= line[index].dist(p);
        p = line[index];
    }
    anchorDistance += line[index].dist(line[index + 1]);
    index++;
    var recentCorners = [];
    var recentAngleDelta = 0;
    while (anchorDistance < labelLength / 2) {
        var prev = line[index - 1];
        var current = line[index];
        var next = line[index + 1];
        if (!next)
            return false;
        var angleDelta = prev.angleTo(current) - current.angleTo(next);
        angleDelta = Math.abs((angleDelta + 3 * Math.PI) % (Math.PI * 2) - Math.PI);
        recentCorners.push({
            distance: anchorDistance,
            angleDelta: angleDelta
        });
        recentAngleDelta += angleDelta;
        while (anchorDistance - recentCorners[0].distance > windowSize) {
            recentAngleDelta -= recentCorners.shift().angleDelta;
        }
        if (recentAngleDelta > maxAngle)
            return false;
        index++;
        anchorDistance += current.dist(next);
    }
    return true;
}