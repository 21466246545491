'use strict';
var Canvas = require('../util/canvas');
var util = require('../util/util');
var browser = require('../util/browser');
var window = require('../util/browser').window;
var Evented = require('../util/evented');
var DOM = require('../util/dom');
var Style = require('../style/style');
var AnimationLoop = require('../style/animation_loop');
var Painter = require('../render/painter');
var Transform = require('../geo/transform');
var Hash = require('./hash');
var bindHandlers = require('./bind_handlers');
var Camera = require('./camera');
var LngLat = require('../geo/lng_lat');
var LngLatBounds = require('../geo/lng_lat_bounds');
var Point = require('point-geometry');
var Attribution = require('./control/attribution');
var defaultMinZoom = 0;
var defaultMaxZoom = 20;
var defaultOptions = {
    center: [
        0,
        0
    ],
    zoom: 0,
    bearing: 0,
    pitch: 0,
    minZoom: defaultMinZoom,
    maxZoom: defaultMaxZoom,
    interactive: true,
    scrollZoom: true,
    boxZoom: true,
    dragRotate: true,
    dragPan: true,
    keyboard: true,
    doubleClickZoom: true,
    touchZoomRotate: true,
    bearingSnap: 7,
    hash: false,
    attributionControl: true,
    failIfMajorPerformanceCaveat: false,
    preserveDrawingBuffer: false,
    trackResize: true,
    workerCount: Math.max(browser.hardwareConcurrency - 1, 1)
};
var Map = module.exports = function (options) {
    options = util.extend({}, defaultOptions, options);
    if (options.workerCount < 1) {
        throw new Error('workerCount must an integer greater than or equal to 1.');
    }
    this._interactive = options.interactive;
    this._failIfMajorPerformanceCaveat = options.failIfMajorPerformanceCaveat;
    this._preserveDrawingBuffer = options.preserveDrawingBuffer;
    this._trackResize = options.trackResize;
    this._workerCount = options.workerCount;
    this._bearingSnap = options.bearingSnap;
    if (typeof options.container === 'string') {
        this._container = document.getElementById(options.container);
    } else {
        this._container = options.container;
    }
    this.animationLoop = new AnimationLoop();
    this.transform = new Transform(options.minZoom, options.maxZoom);
    if (options.maxBounds) {
        this.setMaxBounds(options.maxBounds);
    }
    util.bindAll([
        '_forwardStyleEvent',
        '_forwardSourceEvent',
        '_forwardLayerEvent',
        '_forwardTileEvent',
        '_onStyleLoad',
        '_onStyleChange',
        '_onSourceAdd',
        '_onSourceRemove',
        '_onSourceUpdate',
        '_onWindowOnline',
        '_onWindowResize',
        'onError',
        '_update',
        '_render'
    ], this);
    this._setupContainer();
    this._setupPainter();
    this.on('move', this._update.bind(this, false));
    this.on('zoom', this._update.bind(this, true));
    this.on('moveend', function () {
        this.animationLoop.set(300);
        this._rerender();
    }.bind(this));
    if (typeof window !== 'undefined') {
        window.addEventListener('online', this._onWindowOnline, false);
        window.addEventListener('resize', this._onWindowResize, false);
    }
    bindHandlers(this, options);
    this._hash = options.hash && new Hash().addTo(this);
    if (!this._hash || !this._hash._onHashChange()) {
        this.jumpTo({
            center: options.center,
            zoom: options.zoom,
            bearing: options.bearing,
            pitch: options.pitch
        });
    }
    this.stacks = {};
    this._classes = [];
    this.resize();
    if (options.classes)
        this.setClasses(options.classes);
    if (options.style)
        this.setStyle(options.style);
    if (options.attributionControl)
        this.addControl(new Attribution(options.attributionControl));
    this.on('error', this.onError);
    this.on('style.error', this.onError);
    this.on('source.error', this.onError);
    this.on('tile.error', this.onError);
    this.on('layer.error', this.onError);
};
util.extend(Map.prototype, Evented);
util.extend(Map.prototype, Camera.prototype);
util.extend(Map.prototype, {
    addControl: function (control) {
        control.addTo(this);
        return this;
    },
    addClass: function (klass, options) {
        if (this._classes.indexOf(klass) >= 0 || klass === '')
            return this;
        this._classes.push(klass);
        this._classOptions = options;
        if (this.style)
            this.style.updateClasses();
        return this._update(true);
    },
    removeClass: function (klass, options) {
        var i = this._classes.indexOf(klass);
        if (i < 0 || klass === '')
            return this;
        this._classes.splice(i, 1);
        this._classOptions = options;
        if (this.style)
            this.style.updateClasses();
        return this._update(true);
    },
    setClasses: function (klasses, options) {
        var uniqueClasses = {};
        for (var i = 0; i < klasses.length; i++) {
            if (klasses[i] !== '')
                uniqueClasses[klasses[i]] = true;
        }
        this._classes = Object.keys(uniqueClasses);
        this._classOptions = options;
        if (this.style)
            this.style.updateClasses();
        return this._update(true);
    },
    hasClass: function (klass) {
        return this._classes.indexOf(klass) >= 0;
    },
    getClasses: function () {
        return this._classes;
    },
    resize: function () {
        var width = 0, height = 0;
        if (this._container) {
            width = this._container.offsetWidth || 400;
            height = this._container.offsetHeight || 300;
        }
        this._canvas.resize(width, height);
        this.transform.resize(width, height);
        this.painter.resize(width, height);
        return this.fire('movestart').fire('move').fire('resize').fire('moveend');
    },
    getBounds: function () {
        var bounds = new LngLatBounds(this.transform.pointLocation(new Point(0, 0)), this.transform.pointLocation(this.transform.size));
        if (this.transform.angle || this.transform.pitch) {
            bounds.extend(this.transform.pointLocation(new Point(this.transform.size.x, 0)));
            bounds.extend(this.transform.pointLocation(new Point(0, this.transform.size.y)));
        }
        return bounds;
    },
    setMaxBounds: function (lnglatbounds) {
        if (lnglatbounds) {
            var b = LngLatBounds.convert(lnglatbounds);
            this.transform.lngRange = [
                b.getWest(),
                b.getEast()
            ];
            this.transform.latRange = [
                b.getSouth(),
                b.getNorth()
            ];
            this.transform._constrain();
            this._update();
        } else if (lnglatbounds === null || lnglatbounds === undefined) {
            this.transform.lngRange = [];
            this.transform.latRange = [];
            this._update();
        }
        return this;
    },
    setMinZoom: function (minZoom) {
        minZoom = minZoom === null || minZoom === undefined ? defaultMinZoom : minZoom;
        if (minZoom >= defaultMinZoom && minZoom <= this.transform.maxZoom) {
            this.transform.minZoom = minZoom;
            this._update();
            if (this.getZoom() < minZoom)
                this.setZoom(minZoom);
            return this;
        } else
            throw new Error('minZoom must be between ' + defaultMinZoom + ' and the current maxZoom, inclusive');
    },
    setMaxZoom: function (maxZoom) {
        maxZoom = maxZoom === null || maxZoom === undefined ? defaultMaxZoom : maxZoom;
        if (maxZoom >= this.transform.minZoom && maxZoom <= defaultMaxZoom) {
            this.transform.maxZoom = maxZoom;
            this._update();
            if (this.getZoom() > maxZoom)
                this.setZoom(maxZoom);
            return this;
        } else
            throw new Error('maxZoom must be between the current minZoom and ' + defaultMaxZoom + ', inclusive');
    },
    project: function (lnglat) {
        return this.transform.locationPoint(LngLat.convert(lnglat));
    },
    unproject: function (point) {
        return this.transform.pointLocation(Point.convert(point));
    },
    queryRenderedFeatures: function (pointOrBox, params) {
        if (!(pointOrBox instanceof Point || Array.isArray(pointOrBox))) {
            params = pointOrBox;
            pointOrBox = undefined;
        }
        var queryGeometry = this._makeQueryGeometry(pointOrBox);
        return this.style.queryRenderedFeatures(queryGeometry, params, this.transform.zoom, this.transform.angle);
    },
    _makeQueryGeometry: function (pointOrBox) {
        if (pointOrBox === undefined) {
            pointOrBox = [
                Point.convert([
                    0,
                    0
                ]),
                Point.convert([
                    this.transform.width,
                    this.transform.height
                ])
            ];
        }
        var queryGeometry;
        var isPoint = pointOrBox instanceof Point || typeof pointOrBox[0] === 'number';
        if (isPoint) {
            var point = Point.convert(pointOrBox);
            queryGeometry = [point];
        } else {
            var box = [
                Point.convert(pointOrBox[0]),
                Point.convert(pointOrBox[1])
            ];
            queryGeometry = [
                box[0],
                new Point(box[1].x, box[0].y),
                box[1],
                new Point(box[0].x, box[1].y),
                box[0]
            ];
        }
        queryGeometry = queryGeometry.map(function (p) {
            return this.transform.pointCoordinate(p);
        }.bind(this));
        return queryGeometry;
    },
    querySourceFeatures: function (sourceID, params) {
        return this.style.querySourceFeatures(sourceID, params);
    },
    setStyle: function (style) {
        if (this.style) {
            this.style.off('load', this._onStyleLoad).off('error', this._forwardStyleEvent).off('change', this._onStyleChange).off('source.add', this._onSourceAdd).off('source.remove', this._onSourceRemove).off('source.load', this._onSourceUpdate).off('source.error', this._forwardSourceEvent).off('source.change', this._onSourceUpdate).off('layer.add', this._forwardLayerEvent).off('layer.remove', this._forwardLayerEvent).off('layer.error', this._forwardLayerEvent).off('tile.add', this._forwardTileEvent).off('tile.remove', this._forwardTileEvent).off('tile.load', this._update).off('tile.error', this._forwardTileEvent).off('tile.stats', this._forwardTileEvent)._remove();
            this.off('rotate', this.style._redoPlacement);
            this.off('pitch', this.style._redoPlacement);
        }
        if (!style) {
            this.style = null;
            return this;
        } else if (style instanceof Style) {
            this.style = style;
        } else {
            this.style = new Style(style, this.animationLoop, this._workerCount);
        }
        this.style.on('load', this._onStyleLoad).on('error', this._forwardStyleEvent).on('change', this._onStyleChange).on('source.add', this._onSourceAdd).on('source.remove', this._onSourceRemove).on('source.load', this._onSourceUpdate).on('source.error', this._forwardSourceEvent).on('source.change', this._onSourceUpdate).on('layer.add', this._forwardLayerEvent).on('layer.remove', this._forwardLayerEvent).on('layer.error', this._forwardLayerEvent).on('tile.add', this._forwardTileEvent).on('tile.remove', this._forwardTileEvent).on('tile.load', this._update).on('tile.error', this._forwardTileEvent).on('tile.stats', this._forwardTileEvent);
        this.on('rotate', this.style._redoPlacement);
        this.on('pitch', this.style._redoPlacement);
        return this;
    },
    getStyle: function () {
        if (this.style) {
            return this.style.serialize();
        }
    },
    addSource: function (id, source) {
        this.style.addSource(id, source);
        this._update(true);
        return this;
    },
    removeSource: function (id) {
        this.style.removeSource(id);
        this._update(true);
        return this;
    },
    getSource: function (id) {
        return this.style.getSource(id);
    },
    addLayer: function (layer, before) {
        this.style.addLayer(layer, before);
        this._update(true);
        return this;
    },
    removeLayer: function (id) {
        this.style.removeLayer(id);
        this._update(true);
        return this;
    },
    getLayer: function (id) {
        return this.style.getLayer(id);
    },
    setFilter: function (layer, filter) {
        this.style.setFilter(layer, filter);
        this._update(true);
        return this;
    },
    setLayerZoomRange: function (layerId, minzoom, maxzoom) {
        this.style.setLayerZoomRange(layerId, minzoom, maxzoom);
        this._update(true);
        return this;
    },
    getFilter: function (layer) {
        return this.style.getFilter(layer);
    },
    setPaintProperty: function (layer, name, value, klass) {
        this.style.setPaintProperty(layer, name, value, klass);
        this._update(true);
        return this;
    },
    getPaintProperty: function (layer, name, klass) {
        return this.style.getPaintProperty(layer, name, klass);
    },
    setLayoutProperty: function (layer, name, value) {
        this.style.setLayoutProperty(layer, name, value);
        this._update(true);
        return this;
    },
    getLayoutProperty: function (layer, name) {
        return this.style.getLayoutProperty(layer, name);
    },
    getContainer: function () {
        return this._container;
    },
    getCanvasContainer: function () {
        return this._canvasContainer;
    },
    getCanvas: function () {
        return this._canvas.getElement();
    },
    _setupContainer: function () {
        var container = this._container;
        container.classList.add('mapboxgl-map');
        var canvasContainer = this._canvasContainer = DOM.create('div', 'mapboxgl-canvas-container', container);
        if (this._interactive) {
            canvasContainer.classList.add('mapboxgl-interactive');
        }
        this._canvas = new Canvas(this, canvasContainer);
        var controlContainer = this._controlContainer = DOM.create('div', 'mapboxgl-control-container', container);
        var corners = this._controlCorners = {};
        [
            'top-left',
            'top-right',
            'bottom-left',
            'bottom-right'
        ].forEach(function (pos) {
            corners[pos] = DOM.create('div', 'mapboxgl-ctrl-' + pos, controlContainer);
        });
    },
    _setupPainter: function () {
        var gl = this._canvas.getWebGLContext({
            failIfMajorPerformanceCaveat: this._failIfMajorPerformanceCaveat,
            preserveDrawingBuffer: this._preserveDrawingBuffer
        });
        if (!gl) {
            this.fire('error', { error: new Error('Failed to initialize WebGL') });
            return;
        }
        this.painter = new Painter(gl, this.transform);
    },
    _contextLost: function (event) {
        event.preventDefault();
        if (this._frameId) {
            browser.cancelFrame(this._frameId);
        }
        this.fire('webglcontextlost', { originalEvent: event });
    },
    _contextRestored: function (event) {
        this._setupPainter();
        this.resize();
        this._update();
        this.fire('webglcontextrestored', { originalEvent: event });
    },
    loaded: function () {
        if (this._styleDirty || this._sourcesDirty)
            return false;
        if (!this.style || !this.style.loaded())
            return false;
        return true;
    },
    _update: function (updateStyle) {
        if (!this.style)
            return this;
        this._styleDirty = this._styleDirty || updateStyle;
        this._sourcesDirty = true;
        this._rerender();
        return this;
    },
    _render: function () {
        try {
            if (this.style && this._styleDirty) {
                this._styleDirty = false;
                this.style.update(this._classes, this._classOptions);
                this._classOptions = null;
                this.style._recalculate(this.transform.zoom);
            }
            if (this.style && this._sourcesDirty) {
                this._sourcesDirty = false;
                this.style._updateSources(this.transform);
            }
            this.painter.render(this.style, {
                debug: this.showTileBoundaries,
                showOverdrawInspector: this._showOverdrawInspector,
                vertices: this.vertices,
                rotating: this.rotating,
                zooming: this.zooming
            });
            this.fire('render');
            if (this.loaded() && !this._loaded) {
                this._loaded = true;
                this.fire('load');
            }
            this._frameId = null;
            if (!this.animationLoop.stopped()) {
                this._styleDirty = true;
            }
            if (this._sourcesDirty || this._repaint || !this.animationLoop.stopped()) {
                this._rerender();
            }
        } catch (error) {
            this.fire('error', { error: error });
        }
        return this;
    },
    remove: function () {
        if (this._hash)
            this._hash.remove();
        browser.cancelFrame(this._frameId);
        this.setStyle(null);
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this._onWindowResize, false);
        }
        removeNode(this._canvasContainer);
        removeNode(this._controlContainer);
        this._container.classList.remove('mapboxgl-map');
    },
    onError: function (e) {
        console.error(e.error);
    },
    _rerender: function () {
        if (this.style && !this._frameId) {
            this._frameId = browser.frame(this._render);
        }
    },
    _forwardStyleEvent: function (e) {
        this.fire('style.' + e.type, util.extend({ style: e.target }, e));
    },
    _forwardSourceEvent: function (e) {
        this.fire(e.type, util.extend({ style: e.target }, e));
    },
    _forwardLayerEvent: function (e) {
        this.fire(e.type, util.extend({ style: e.target }, e));
    },
    _forwardTileEvent: function (e) {
        this.fire(e.type, util.extend({ style: e.target }, e));
    },
    _onStyleLoad: function (e) {
        if (this.transform.unmodified) {
            this.jumpTo(this.style.stylesheet);
        }
        this.style.update(this._classes, { transition: false });
        this._forwardStyleEvent(e);
    },
    _onStyleChange: function (e) {
        this._update(true);
        this._forwardStyleEvent(e);
    },
    _onSourceAdd: function (e) {
        var source = e.source;
        if (source.onAdd)
            source.onAdd(this);
        this._forwardSourceEvent(e);
    },
    _onSourceRemove: function (e) {
        var source = e.source;
        if (source.onRemove)
            source.onRemove(this);
        this._forwardSourceEvent(e);
    },
    _onSourceUpdate: function (e) {
        this._update();
        this._forwardSourceEvent(e);
    },
    _onWindowOnline: function () {
        this._update();
    },
    _onWindowResize: function () {
        if (this._trackResize) {
            this.stop().resize()._update();
        }
    }
});
util.extendAll(Map.prototype, {
    _showTileBoundaries: false,
    get showTileBoundaries() {
        return this._showTileBoundaries;
    },
    set showTileBoundaries(value) {
        if (this._showTileBoundaries === value)
            return;
        this._showTileBoundaries = value;
        this._update();
    },
    _showCollisionBoxes: false,
    get showCollisionBoxes() {
        return this._showCollisionBoxes;
    },
    set showCollisionBoxes(value) {
        if (this._showCollisionBoxes === value)
            return;
        this._showCollisionBoxes = value;
        this.style._redoPlacement();
    },
    _showOverdrawInspector: false,
    get showOverdrawInspector() {
        return this._showOverdrawInspector;
    },
    set showOverdrawInspector(value) {
        if (this._showOverdrawInspector === value)
            return;
        this._showOverdrawInspector = value;
        this._update();
    },
    _repaint: false,
    get repaint() {
        return this._repaint;
    },
    set repaint(value) {
        this._repaint = value;
        this._update();
    },
    _vertices: false,
    get vertices() {
        return this._vertices;
    },
    set vertices(value) {
        this._vertices = value;
        this._update();
    }
});
function removeNode(node) {
    if (node.parentNode) {
        node.parentNode.removeChild(node);
    }
}