'use strict';
var util = require('../../util/util');
var StyleLayer = require('../style_layer');
function LineStyleLayer() {
    StyleLayer.apply(this, arguments);
}
module.exports = LineStyleLayer;
LineStyleLayer.prototype = util.inherit(StyleLayer, {
    getPaintValue: function (name, globalProperties, featureProperties) {
        var value = StyleLayer.prototype.getPaintValue.apply(this, arguments);
        if (value && name === 'line-dasharray') {
            var flooredZoom = Math.floor(globalProperties.zoom);
            if (this._flooredZoom !== flooredZoom) {
                this._flooredZoom = flooredZoom;
                this._flooredLineWidth = this.getPaintValue('line-width', globalProperties, featureProperties);
            }
            value.fromScale *= this._flooredLineWidth;
            value.toScale *= this._flooredLineWidth;
        }
        return value;
    }
});