'use strict';
module.exports = LRUCache;
function LRUCache(max, onRemove) {
    this.max = max;
    this.onRemove = onRemove;
    this.reset();
}
LRUCache.prototype.reset = function () {
    for (var key in this.data) {
        this.onRemove(this.data[key]);
    }
    this.data = {};
    this.order = [];
    return this;
};
LRUCache.prototype.add = function (key, data) {
    if (this.has(key)) {
        this.order.splice(this.order.indexOf(key), 1);
        this.data[key] = data;
        this.order.push(key);
    } else {
        this.data[key] = data;
        this.order.push(key);
        if (this.order.length > this.max) {
            var removedData = this.get(this.order[0]);
            if (removedData)
                this.onRemove(removedData);
        }
    }
    return this;
};
LRUCache.prototype.has = function (key) {
    return key in this.data;
};
LRUCache.prototype.keys = function () {
    return this.order;
};
LRUCache.prototype.get = function (key) {
    if (!this.has(key)) {
        return null;
    }
    var data = this.data[key];
    delete this.data[key];
    this.order.splice(this.order.indexOf(key), 1);
    return data;
};
LRUCache.prototype.setMaxSize = function (max) {
    this.max = max;
    while (this.order.length > this.max) {
        var removedData = this.get(this.order[0]);
        if (removedData)
            this.onRemove(removedData);
    }
    return this;
};