'use strict';
module.exports = Marker;
var DOM = require('../util/dom');
var LngLat = require('../geo/lng_lat');
function Marker(element) {
    if (!element) {
        element = DOM.create('div');
    }
    element.classList.add('mapboxgl-marker');
    this._el = element;
    this._update = this._update.bind(this);
}
Marker.prototype = {
    addTo: function (map) {
        this.remove();
        this._map = map;
        map.getCanvasContainer().appendChild(this._el);
        map.on('move', this._update);
        this._update();
        return this;
    },
    remove: function () {
        if (this._map) {
            this._map.off('move', this._update);
            this._map = null;
        }
        var parent = this._el.parentNode;
        if (parent)
            parent.removeChild(this._el);
        return this;
    },
    getLngLat: function () {
        return this._lngLat;
    },
    setLngLat: function (lnglat) {
        this._lngLat = LngLat.convert(lnglat);
        this._update();
        return this;
    },
    getElement: function () {
        return this._el;
    },
    _update: function () {
        if (!this._map)
            return;
        var pos = this._map.project(this._lngLat);
        DOM.setTransform(this._el, 'translate(' + pos.x + 'px,' + pos.y + 'px)');
    }
};