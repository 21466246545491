'use strict';
var StructArrayType = require('../util/struct_array');
var util = require('../util/util');
var Point = require('point-geometry');
var CollisionBoxArray = module.exports = new StructArrayType({
    members: [
        {
            type: 'Int16',
            name: 'anchorPointX'
        },
        {
            type: 'Int16',
            name: 'anchorPointY'
        },
        {
            type: 'Int16',
            name: 'x1'
        },
        {
            type: 'Int16',
            name: 'y1'
        },
        {
            type: 'Int16',
            name: 'x2'
        },
        {
            type: 'Int16',
            name: 'y2'
        },
        {
            type: 'Float32',
            name: 'maxScale'
        },
        {
            type: 'Uint32',
            name: 'featureIndex'
        },
        {
            type: 'Uint16',
            name: 'sourceLayerIndex'
        },
        {
            type: 'Uint16',
            name: 'bucketIndex'
        },
        {
            type: 'Int16',
            name: 'bbox0'
        },
        {
            type: 'Int16',
            name: 'bbox1'
        },
        {
            type: 'Int16',
            name: 'bbox2'
        },
        {
            type: 'Int16',
            name: 'bbox3'
        },
        {
            type: 'Float32',
            name: 'placementScale'
        }
    ]
});
util.extendAll(CollisionBoxArray.prototype.StructType.prototype, {
    get anchorPoint() {
        return new Point(this.anchorPointX, this.anchorPointY);
    }
});