'use strict';
var Bucket = require('../bucket');
var util = require('../../util/util');
var loadGeometry = require('../load_geometry');
var EXTENT = Bucket.EXTENT;
var EXTRUDE_SCALE = 63;
var COS_HALF_SHARP_CORNER = Math.cos(75 / 2 * (Math.PI / 180));
var SHARP_CORNER_OFFSET = 15;
var LINE_DISTANCE_BUFFER_BITS = 15;
var LINE_DISTANCE_SCALE = 1 / 2;
var MAX_LINE_DISTANCE = Math.pow(2, LINE_DISTANCE_BUFFER_BITS - 1) / LINE_DISTANCE_SCALE;
module.exports = LineBucket;
function LineBucket() {
    Bucket.apply(this, arguments);
}
LineBucket.prototype = util.inherit(Bucket, {});
LineBucket.prototype.addLineVertex = function (vertexBuffer, point, extrude, tx, ty, dir, linesofar) {
    return vertexBuffer.emplaceBack(point.x << 1 | tx, point.y << 1 | ty, Math.round(EXTRUDE_SCALE * extrude.x) + 128, Math.round(EXTRUDE_SCALE * extrude.y) + 128, (dir === 0 ? 0 : dir < 0 ? -1 : 1) + 1 | (linesofar * LINE_DISTANCE_SCALE & 63) << 2, linesofar * LINE_DISTANCE_SCALE >> 6);
};
LineBucket.prototype.programInterfaces = {
    line: {
        vertexBuffer: true,
        elementBuffer: true,
        layoutAttributes: [
            {
                name: 'a_pos',
                components: 2,
                type: 'Int16'
            },
            {
                name: 'a_data',
                components: 4,
                type: 'Uint8'
            }
        ]
    }
};
LineBucket.prototype.addFeature = function (feature) {
    var lines = loadGeometry(feature, LINE_DISTANCE_BUFFER_BITS);
    for (var i = 0; i < lines.length; i++) {
        this.addLine(lines[i], this.layer.layout['line-join'], this.layer.layout['line-cap'], this.layer.layout['line-miter-limit'], this.layer.layout['line-round-limit']);
    }
};
LineBucket.prototype.addLine = function (vertices, join, cap, miterLimit, roundLimit) {
    var len = vertices.length;
    while (len > 2 && vertices[len - 1].equals(vertices[len - 2])) {
        len--;
    }
    if (vertices.length < 2)
        return;
    if (join === 'bevel')
        miterLimit = 1.05;
    var sharpCornerOffset = SHARP_CORNER_OFFSET * (EXTENT / (512 * this.overscaling));
    var firstVertex = vertices[0], lastVertex = vertices[len - 1], closed = firstVertex.equals(lastVertex);
    this.prepareArrayGroup('line', len * 10);
    if (len === 2 && closed)
        return;
    this.distance = 0;
    var beginCap = cap, endCap = closed ? 'butt' : cap, startOfLine = true, currentVertex, prevVertex, nextVertex, prevNormal, nextNormal, offsetA, offsetB;
    this.e1 = this.e2 = this.e3 = -1;
    if (closed) {
        currentVertex = vertices[len - 2];
        nextNormal = firstVertex.sub(currentVertex)._unit()._perp();
    }
    for (var i = 0; i < len; i++) {
        nextVertex = closed && i === len - 1 ? vertices[1] : vertices[i + 1];
        if (nextVertex && vertices[i].equals(nextVertex))
            continue;
        if (nextNormal)
            prevNormal = nextNormal;
        if (currentVertex)
            prevVertex = currentVertex;
        currentVertex = vertices[i];
        nextNormal = nextVertex ? nextVertex.sub(currentVertex)._unit()._perp() : prevNormal;
        prevNormal = prevNormal || nextNormal;
        var joinNormal = prevNormal.add(nextNormal)._unit();
        var cosHalfAngle = joinNormal.x * nextNormal.x + joinNormal.y * nextNormal.y;
        var miterLength = 1 / cosHalfAngle;
        var isSharpCorner = cosHalfAngle < COS_HALF_SHARP_CORNER && prevVertex && nextVertex;
        if (isSharpCorner && i > 0) {
            var prevSegmentLength = currentVertex.dist(prevVertex);
            if (prevSegmentLength > 2 * sharpCornerOffset) {
                var newPrevVertex = currentVertex.sub(currentVertex.sub(prevVertex)._mult(sharpCornerOffset / prevSegmentLength)._round());
                this.distance += newPrevVertex.dist(prevVertex);
                this.addCurrentVertex(newPrevVertex, this.distance, prevNormal.mult(1), 0, 0, false);
                prevVertex = newPrevVertex;
            }
        }
        var middleVertex = prevVertex && nextVertex;
        var currentJoin = middleVertex ? join : nextVertex ? beginCap : endCap;
        if (middleVertex && currentJoin === 'round') {
            if (miterLength < roundLimit) {
                currentJoin = 'miter';
            } else if (miterLength <= 2) {
                currentJoin = 'fakeround';
            }
        }
        if (currentJoin === 'miter' && miterLength > miterLimit) {
            currentJoin = 'bevel';
        }
        if (currentJoin === 'bevel') {
            if (miterLength > 2)
                currentJoin = 'flipbevel';
            if (miterLength < miterLimit)
                currentJoin = 'miter';
        }
        if (prevVertex)
            this.distance += currentVertex.dist(prevVertex);
        if (currentJoin === 'miter') {
            joinNormal._mult(miterLength);
            this.addCurrentVertex(currentVertex, this.distance, joinNormal, 0, 0, false);
        } else if (currentJoin === 'flipbevel') {
            if (miterLength > 100) {
                joinNormal = nextNormal.clone();
            } else {
                var direction = prevNormal.x * nextNormal.y - prevNormal.y * nextNormal.x > 0 ? -1 : 1;
                var bevelLength = miterLength * prevNormal.add(nextNormal).mag() / prevNormal.sub(nextNormal).mag();
                joinNormal._perp()._mult(bevelLength * direction);
            }
            this.addCurrentVertex(currentVertex, this.distance, joinNormal, 0, 0, false);
            this.addCurrentVertex(currentVertex, this.distance, joinNormal.mult(-1), 0, 0, false);
        } else if (currentJoin === 'bevel' || currentJoin === 'fakeround') {
            var lineTurnsLeft = prevNormal.x * nextNormal.y - prevNormal.y * nextNormal.x > 0;
            var offset = -Math.sqrt(miterLength * miterLength - 1);
            if (lineTurnsLeft) {
                offsetB = 0;
                offsetA = offset;
            } else {
                offsetA = 0;
                offsetB = offset;
            }
            if (!startOfLine) {
                this.addCurrentVertex(currentVertex, this.distance, prevNormal, offsetA, offsetB, false);
            }
            if (currentJoin === 'fakeround') {
                var n = Math.floor((0.5 - (cosHalfAngle - 0.5)) * 8);
                var approxFractionalJoinNormal;
                for (var m = 0; m < n; m++) {
                    approxFractionalJoinNormal = nextNormal.mult((m + 1) / (n + 1))._add(prevNormal)._unit();
                    this.addPieSliceVertex(currentVertex, this.distance, approxFractionalJoinNormal, lineTurnsLeft);
                }
                this.addPieSliceVertex(currentVertex, this.distance, joinNormal, lineTurnsLeft);
                for (var k = n - 1; k >= 0; k--) {
                    approxFractionalJoinNormal = prevNormal.mult((k + 1) / (n + 1))._add(nextNormal)._unit();
                    this.addPieSliceVertex(currentVertex, this.distance, approxFractionalJoinNormal, lineTurnsLeft);
                }
            }
            if (nextVertex) {
                this.addCurrentVertex(currentVertex, this.distance, nextNormal, -offsetA, -offsetB, false);
            }
        } else if (currentJoin === 'butt') {
            if (!startOfLine) {
                this.addCurrentVertex(currentVertex, this.distance, prevNormal, 0, 0, false);
            }
            if (nextVertex) {
                this.addCurrentVertex(currentVertex, this.distance, nextNormal, 0, 0, false);
            }
        } else if (currentJoin === 'square') {
            if (!startOfLine) {
                this.addCurrentVertex(currentVertex, this.distance, prevNormal, 1, 1, false);
                this.e1 = this.e2 = -1;
            }
            if (nextVertex) {
                this.addCurrentVertex(currentVertex, this.distance, nextNormal, -1, -1, false);
            }
        } else if (currentJoin === 'round') {
            if (!startOfLine) {
                this.addCurrentVertex(currentVertex, this.distance, prevNormal, 0, 0, false);
                this.addCurrentVertex(currentVertex, this.distance, prevNormal, 1, 1, true);
                this.e1 = this.e2 = -1;
            }
            if (nextVertex) {
                this.addCurrentVertex(currentVertex, this.distance, nextNormal, -1, -1, true);
                this.addCurrentVertex(currentVertex, this.distance, nextNormal, 0, 0, false);
            }
        }
        if (isSharpCorner && i < len - 1) {
            var nextSegmentLength = currentVertex.dist(nextVertex);
            if (nextSegmentLength > 2 * sharpCornerOffset) {
                var newCurrentVertex = currentVertex.add(nextVertex.sub(currentVertex)._mult(sharpCornerOffset / nextSegmentLength)._round());
                this.distance += newCurrentVertex.dist(currentVertex);
                this.addCurrentVertex(newCurrentVertex, this.distance, nextNormal.mult(1), 0, 0, false);
                currentVertex = newCurrentVertex;
            }
        }
        startOfLine = false;
    }
};
LineBucket.prototype.addCurrentVertex = function (currentVertex, distance, normal, endLeft, endRight, round) {
    var tx = round ? 1 : 0;
    var extrude;
    var layoutArrays = this.arrayGroups.line[this.arrayGroups.line.length - 1].layout;
    var vertexArray = layoutArrays.vertex;
    var elementArray = layoutArrays.element;
    extrude = normal.clone();
    if (endLeft)
        extrude._sub(normal.perp()._mult(endLeft));
    this.e3 = this.addLineVertex(vertexArray, currentVertex, extrude, tx, 0, endLeft, distance);
    if (this.e1 >= 0 && this.e2 >= 0) {
        elementArray.emplaceBack(this.e1, this.e2, this.e3);
    }
    this.e1 = this.e2;
    this.e2 = this.e3;
    extrude = normal.mult(-1);
    if (endRight)
        extrude._sub(normal.perp()._mult(endRight));
    this.e3 = this.addLineVertex(vertexArray, currentVertex, extrude, tx, 1, -endRight, distance);
    if (this.e1 >= 0 && this.e2 >= 0) {
        elementArray.emplaceBack(this.e1, this.e2, this.e3);
    }
    this.e1 = this.e2;
    this.e2 = this.e3;
    if (distance > MAX_LINE_DISTANCE / 2) {
        this.distance = 0;
        this.addCurrentVertex(currentVertex, this.distance, normal, endLeft, endRight, round);
    }
};
LineBucket.prototype.addPieSliceVertex = function (currentVertex, distance, extrude, lineTurnsLeft) {
    var ty = lineTurnsLeft ? 1 : 0;
    extrude = extrude.mult(lineTurnsLeft ? -1 : 1);
    var layoutArrays = this.arrayGroups.line[this.arrayGroups.line.length - 1].layout;
    var vertexArray = layoutArrays.vertex;
    var elementArray = layoutArrays.element;
    this.e3 = this.addLineVertex(vertexArray, currentVertex, extrude, 0, ty, 0, distance);
    if (this.e1 >= 0 && this.e2 >= 0) {
        elementArray.emplaceBack(this.e1, this.e2, this.e3);
    }
    if (lineTurnsLeft) {
        this.e2 = this.e3;
    } else {
        this.e1 = this.e3;
    }
};