'use strict';
var UnitBezier = require('unitbezier');
var Coordinate = require('../geo/coordinate');
exports.easeCubicInOut = function (t) {
    if (t <= 0)
        return 0;
    if (t >= 1)
        return 1;
    var t2 = t * t, t3 = t2 * t;
    return 4 * (t < 0.5 ? t3 : 3 * (t - t2) + t3 - 0.75);
};
exports.bezier = function (p1x, p1y, p2x, p2y) {
    var bezier = new UnitBezier(p1x, p1y, p2x, p2y);
    return function (t) {
        return bezier.solve(t);
    };
};
exports.ease = exports.bezier(0.25, 0.1, 0.25, 1);
exports.clamp = function (n, min, max) {
    return Math.min(max, Math.max(min, n));
};
exports.wrap = function (n, min, max) {
    var d = max - min;
    var w = ((n - min) % d + d) % d + min;
    return w === min ? max : w;
};
exports.coalesce = function () {
    for (var i = 0; i < arguments.length; i++) {
        var arg = arguments[i];
        if (arg !== null && arg !== undefined)
            return arg;
    }
};
exports.asyncAll = function (array, fn, callback) {
    if (!array.length) {
        return callback(null, []);
    }
    var remaining = array.length;
    var results = new Array(array.length);
    var error = null;
    array.forEach(function (item, i) {
        fn(item, function (err, result) {
            if (err)
                error = err;
            results[i] = result;
            if (--remaining === 0)
                callback(error, results);
        });
    });
};
exports.keysDifference = function (obj, other) {
    var difference = [];
    for (var i in obj) {
        if (!(i in other)) {
            difference.push(i);
        }
    }
    return difference;
};
exports.extend = function (dest) {
    for (var i = 1; i < arguments.length; i++) {
        var src = arguments[i];
        for (var k in src) {
            dest[k] = src[k];
        }
    }
    return dest;
};
exports.extendAll = function (dest, src) {
    for (var i in src) {
        Object.defineProperty(dest, i, Object.getOwnPropertyDescriptor(src, i));
    }
    return dest;
};
exports.inherit = function (parent, props) {
    var parentProto = typeof parent === 'function' ? parent.prototype : parent, proto = Object.create(parentProto);
    exports.extendAll(proto, props);
    return proto;
};
exports.pick = function (src, properties) {
    var result = {};
    for (var i = 0; i < properties.length; i++) {
        var k = properties[i];
        if (k in src) {
            result[k] = src[k];
        }
    }
    return result;
};
var id = 1;
exports.uniqueId = function () {
    return id++;
};
exports.debounce = function (fn, time) {
    var timer, args;
    return function () {
        args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(null, args);
        }, time);
    };
};
exports.bindAll = function (fns, context) {
    fns.forEach(function (fn) {
        context[fn] = context[fn].bind(context);
    });
};
exports.bindHandlers = function (context) {
    for (var i in context) {
        if (typeof context[i] === 'function' && i.indexOf('_on') === 0) {
            context[i] = context[i].bind(context);
        }
    }
};
exports.setOptions = function (obj, options) {
    if (!obj.hasOwnProperty('options')) {
        obj.options = obj.options ? Object.create(obj.options) : {};
    }
    for (var i in options) {
        obj.options[i] = options[i];
    }
    return obj.options;
};
exports.getCoordinatesCenter = function (coords) {
    var minX = Infinity;
    var minY = Infinity;
    var maxX = -Infinity;
    var maxY = -Infinity;
    for (var i = 0; i < coords.length; i++) {
        minX = Math.min(minX, coords[i].column);
        minY = Math.min(minY, coords[i].row);
        maxX = Math.max(maxX, coords[i].column);
        maxY = Math.max(maxY, coords[i].row);
    }
    var dx = maxX - minX;
    var dy = maxY - minY;
    var dMax = Math.max(dx, dy);
    return new Coordinate((minX + maxX) / 2, (minY + maxY) / 2, 0).zoomTo(Math.floor(-Math.log(dMax) / Math.LN2));
};
exports.endsWith = function (string, suffix) {
    return string.indexOf(suffix, string.length - suffix.length) !== -1;
};
exports.startsWith = function (string, prefix) {
    return string.indexOf(prefix) === 0;
};
exports.mapObject = function (input, iterator, context) {
    var output = {};
    for (var key in input) {
        output[key] = iterator.call(context || this, input[key], key, input);
    }
    return output;
};
exports.filterObject = function (input, iterator, context) {
    var output = {};
    for (var key in input) {
        if (iterator.call(context || this, input[key], key, input)) {
            output[key] = input[key];
        }
    }
    return output;
};
exports.deepEqual = function deepEqual(a, b) {
    if (Array.isArray(a)) {
        if (!Array.isArray(b) || a.length !== b.length)
            return false;
        for (var i = 0; i < a.length; i++) {
            if (!deepEqual(a[i], b[i]))
                return false;
        }
        return true;
    }
    if (typeof a === 'object' && a !== null && b !== null) {
        if (!(typeof b === 'object'))
            return false;
        var keys = Object.keys(a);
        if (keys.length !== Object.keys(b).length)
            return false;
        for (var key in a) {
            if (!deepEqual(a[key], b[key]))
                return false;
        }
        return true;
    }
    return a === b;
};
exports.clone = function deepEqual(input) {
    if (Array.isArray(input)) {
        return input.map(exports.clone);
    } else if (typeof input === 'object') {
        return exports.mapObject(input, exports.clone);
    } else {
        return input;
    }
};
exports.arraysIntersect = function (a, b) {
    for (var l = 0; l < a.length; l++) {
        if (b.indexOf(a[l]) >= 0)
            return true;
    }
    return false;
};
var warnOnceHistory = {};
exports.warnOnce = function (message) {
    if (!warnOnceHistory[message]) {
        if (typeof console !== 'undefined')
            console.warn(message);
        warnOnceHistory[message] = true;
    }
};