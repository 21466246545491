'use strict';
var util = require('../util/util');
var interpolate = require('../util/interpolate');
var browser = require('../util/browser');
var LngLat = require('../geo/lng_lat');
var LngLatBounds = require('../geo/lng_lat_bounds');
var Point = require('point-geometry');
var Camera = module.exports = function () {
};
util.extend(Camera.prototype, {
    getCenter: function () {
        return this.transform.center;
    },
    setCenter: function (center, eventData) {
        this.jumpTo({ center: center }, eventData);
        return this;
    },
    panBy: function (offset, options, eventData) {
        this.panTo(this.transform.center, util.extend({ offset: Point.convert(offset).mult(-1) }, options), eventData);
        return this;
    },
    panTo: function (lnglat, options, eventData) {
        return this.easeTo(util.extend({ center: lnglat }, options), eventData);
    },
    getZoom: function () {
        return this.transform.zoom;
    },
    setZoom: function (zoom, eventData) {
        this.jumpTo({ zoom: zoom }, eventData);
        return this;
    },
    zoomTo: function (zoom, options, eventData) {
        return this.easeTo(util.extend({ zoom: zoom }, options), eventData);
    },
    zoomIn: function (options, eventData) {
        this.zoomTo(this.getZoom() + 1, options, eventData);
        return this;
    },
    zoomOut: function (options, eventData) {
        this.zoomTo(this.getZoom() - 1, options, eventData);
        return this;
    },
    getBearing: function () {
        return this.transform.bearing;
    },
    setBearing: function (bearing, eventData) {
        this.jumpTo({ bearing: bearing }, eventData);
        return this;
    },
    rotateTo: function (bearing, options, eventData) {
        return this.easeTo(util.extend({ bearing: bearing }, options), eventData);
    },
    resetNorth: function (options, eventData) {
        this.rotateTo(0, util.extend({ duration: 1000 }, options), eventData);
        return this;
    },
    snapToNorth: function (options, eventData) {
        if (Math.abs(this.getBearing()) < this._bearingSnap) {
            return this.resetNorth(options, eventData);
        }
        return this;
    },
    getPitch: function () {
        return this.transform.pitch;
    },
    setPitch: function (pitch, eventData) {
        this.jumpTo({ pitch: pitch }, eventData);
        return this;
    },
    fitBounds: function (bounds, options, eventData) {
        options = util.extend({
            padding: 0,
            offset: [
                0,
                0
            ],
            maxZoom: Infinity
        }, options);
        bounds = LngLatBounds.convert(bounds);
        var offset = Point.convert(options.offset), tr = this.transform, nw = tr.project(bounds.getNorthWest()), se = tr.project(bounds.getSouthEast()), size = se.sub(nw), scaleX = (tr.width - options.padding * 2 - Math.abs(offset.x) * 2) / size.x, scaleY = (tr.height - options.padding * 2 - Math.abs(offset.y) * 2) / size.y;
        options.center = tr.unproject(nw.add(se).div(2));
        options.zoom = Math.min(tr.scaleZoom(tr.scale * Math.min(scaleX, scaleY)), options.maxZoom);
        options.bearing = 0;
        return options.linear ? this.easeTo(options, eventData) : this.flyTo(options, eventData);
    },
    jumpTo: function (options, eventData) {
        this.stop();
        var tr = this.transform, zoomChanged = false, bearingChanged = false, pitchChanged = false;
        if ('zoom' in options && tr.zoom !== +options.zoom) {
            zoomChanged = true;
            tr.zoom = +options.zoom;
        }
        if ('center' in options) {
            tr.center = LngLat.convert(options.center);
        }
        if ('bearing' in options && tr.bearing !== +options.bearing) {
            bearingChanged = true;
            tr.bearing = +options.bearing;
        }
        if ('pitch' in options && tr.pitch !== +options.pitch) {
            pitchChanged = true;
            tr.pitch = +options.pitch;
        }
        this.fire('movestart', eventData).fire('move', eventData);
        if (zoomChanged) {
            this.fire('zoomstart', eventData).fire('zoom', eventData).fire('zoomend', eventData);
        }
        if (bearingChanged) {
            this.fire('rotate', eventData);
        }
        if (pitchChanged) {
            this.fire('pitch', eventData);
        }
        return this.fire('moveend', eventData);
    },
    easeTo: function (options, eventData) {
        this.stop();
        options = util.extend({
            offset: [
                0,
                0
            ],
            duration: 500,
            easing: util.ease
        }, options);
        var tr = this.transform, offset = Point.convert(options.offset), startZoom = this.getZoom(), startBearing = this.getBearing(), startPitch = this.getPitch(), zoom = 'zoom' in options ? +options.zoom : startZoom, bearing = 'bearing' in options ? this._normalizeBearing(options.bearing, startBearing) : startBearing, pitch = 'pitch' in options ? +options.pitch : startPitch, toLngLat, toPoint;
        if ('center' in options) {
            toLngLat = LngLat.convert(options.center);
            toPoint = tr.centerPoint.add(offset);
        } else if ('around' in options) {
            toLngLat = LngLat.convert(options.around);
            toPoint = tr.locationPoint(toLngLat);
        } else {
            toPoint = tr.centerPoint.add(offset);
            toLngLat = tr.pointLocation(toPoint);
        }
        var fromPoint = tr.locationPoint(toLngLat);
        if (options.animate === false)
            options.duration = 0;
        this.zooming = zoom !== startZoom;
        this.rotating = startBearing !== bearing;
        this.pitching = pitch !== startPitch;
        if (!options.noMoveStart) {
            this.fire('movestart', eventData);
        }
        if (this.zooming) {
            this.fire('zoomstart', eventData);
        }
        clearTimeout(this._onEaseEnd);
        this._ease(function (k) {
            if (this.zooming) {
                tr.zoom = interpolate(startZoom, zoom, k);
            }
            if (this.rotating) {
                tr.bearing = interpolate(startBearing, bearing, k);
            }
            if (this.pitching) {
                tr.pitch = interpolate(startPitch, pitch, k);
            }
            tr.setLocationAtPoint(toLngLat, fromPoint.add(toPoint.sub(fromPoint)._mult(k)));
            this.fire('move', eventData);
            if (this.zooming) {
                this.fire('zoom', eventData);
            }
            if (this.rotating) {
                this.fire('rotate', eventData);
            }
            if (this.pitching) {
                this.fire('pitch', eventData);
            }
        }, function () {
            if (options.delayEndEvents) {
                this._onEaseEnd = setTimeout(this._easeToEnd.bind(this, eventData), options.delayEndEvents);
            } else {
                this._easeToEnd(eventData);
            }
        }.bind(this), options);
        return this;
    },
    _easeToEnd: function (eventData) {
        if (this.zooming) {
            this.fire('zoomend', eventData);
        }
        this.fire('moveend', eventData);
        this.zooming = false;
        this.rotating = false;
        this.pitching = false;
    },
    flyTo: function (options, eventData) {
        this.stop();
        options = util.extend({
            offset: [
                0,
                0
            ],
            speed: 1.2,
            curve: 1.42,
            easing: util.ease
        }, options);
        var tr = this.transform, offset = Point.convert(options.offset), startZoom = this.getZoom(), startBearing = this.getBearing(), startPitch = this.getPitch();
        var center = 'center' in options ? LngLat.convert(options.center) : this.getCenter();
        var zoom = 'zoom' in options ? +options.zoom : startZoom;
        var bearing = 'bearing' in options ? this._normalizeBearing(options.bearing, startBearing) : startBearing;
        var pitch = 'pitch' in options ? +options.pitch : startPitch;
        if (Math.abs(tr.center.lng) + Math.abs(center.lng) > 180) {
            if (tr.center.lng > 0 && center.lng < 0) {
                center.lng += 360;
            } else if (tr.center.lng < 0 && center.lng > 0) {
                center.lng -= 360;
            }
        }
        var scale = tr.zoomScale(zoom - startZoom), from = tr.point, to = 'center' in options ? tr.project(center).sub(offset.div(scale)) : from;
        var startWorldSize = tr.worldSize, rho = options.curve, w0 = Math.max(tr.width, tr.height), w1 = w0 / scale, u1 = to.sub(from).mag();
        if ('minZoom' in options) {
            var minZoom = util.clamp(Math.min(options.minZoom, startZoom, zoom), tr.minZoom, tr.maxZoom);
            var wMax = w0 / tr.zoomScale(minZoom - startZoom);
            rho = Math.sqrt(wMax / u1 * 2);
        }
        var rho2 = rho * rho;
        function r(i) {
            var b = (w1 * w1 - w0 * w0 + (i ? -1 : 1) * rho2 * rho2 * u1 * u1) / (2 * (i ? w1 : w0) * rho2 * u1);
            return Math.log(Math.sqrt(b * b + 1) - b);
        }
        function sinh(n) {
            return (Math.exp(n) - Math.exp(-n)) / 2;
        }
        function cosh(n) {
            return (Math.exp(n) + Math.exp(-n)) / 2;
        }
        function tanh(n) {
            return sinh(n) / cosh(n);
        }
        var r0 = r(0), w = function (s) {
                return cosh(r0) / cosh(r0 + rho * s);
            }, u = function (s) {
                return w0 * ((cosh(r0) * tanh(r0 + rho * s) - sinh(r0)) / rho2) / u1;
            }, S = (r(1) - r0) / rho;
        if (Math.abs(u1) < 0.000001) {
            if (Math.abs(w0 - w1) < 0.000001)
                return this.easeTo(options);
            var k = w1 < w0 ? -1 : 1;
            S = Math.abs(Math.log(w1 / w0)) / rho;
            u = function () {
                return 0;
            };
            w = function (s) {
                return Math.exp(k * rho * s);
            };
        }
        if ('duration' in options) {
            options.duration = +options.duration;
        } else {
            var V = 'screenSpeed' in options ? +options.screenSpeed / rho : +options.speed;
            options.duration = 1000 * S / V;
        }
        this.zooming = true;
        if (startBearing !== bearing)
            this.rotating = true;
        if (startPitch !== pitch)
            this.pitching = true;
        this.fire('movestart', eventData);
        this.fire('zoomstart', eventData);
        this._ease(function (k) {
            var s = k * S, us = u(s);
            tr.zoom = startZoom + tr.scaleZoom(1 / w(s));
            tr.center = tr.unproject(from.add(to.sub(from).mult(us)), startWorldSize);
            if (this.rotating) {
                tr.bearing = interpolate(startBearing, bearing, k);
            }
            if (this.pitching) {
                tr.pitch = interpolate(startPitch, pitch, k);
            }
            this.fire('move', eventData);
            this.fire('zoom', eventData);
            if (this.rotating) {
                this.fire('rotate', eventData);
            }
            if (this.pitching) {
                this.fire('pitch', eventData);
            }
        }, function () {
            this.fire('zoomend', eventData);
            this.fire('moveend', eventData);
            this.zooming = false;
            this.rotating = false;
            this.pitching = false;
        }, options);
        return this;
    },
    isEasing: function () {
        return !!this._abortFn;
    },
    stop: function () {
        if (this._abortFn) {
            this._abortFn();
            this._finishEase();
        }
        return this;
    },
    _ease: function (frame, finish, options) {
        this._finishFn = finish;
        this._abortFn = browser.timed(function (t) {
            frame.call(this, options.easing(t));
            if (t === 1) {
                this._finishEase();
            }
        }, options.animate === false ? 0 : options.duration, this);
    },
    _finishEase: function () {
        delete this._abortFn;
        var finish = this._finishFn;
        delete this._finishFn;
        finish.call(this);
    },
    _normalizeBearing: function (bearing, currentBearing) {
        bearing = util.wrap(bearing, -180, 180);
        var diff = Math.abs(bearing - currentBearing);
        if (Math.abs(bearing - 360 - currentBearing) < diff)
            bearing -= 360;
        if (Math.abs(bearing + 360 - currentBearing) < diff)
            bearing += 360;
        return bearing;
    },
    _updateEasing: function (duration, zoom, bezier) {
        var easing;
        if (this.ease) {
            var ease = this.ease, t = (Date.now() - ease.start) / ease.duration, speed = ease.easing(t + 0.01) - ease.easing(t), x = 0.27 / Math.sqrt(speed * speed + 0.0001) * 0.01, y = Math.sqrt(0.27 * 0.27 - x * x);
            easing = util.bezier(x, y, 0.25, 1);
        } else {
            easing = bezier ? util.bezier.apply(util, bezier) : util.ease;
        }
        this.ease = {
            start: new Date().getTime(),
            to: Math.pow(2, zoom),
            duration: duration,
            easing: easing
        };
        return easing;
    }
});