'use strict';
var Tile = require('./tile');
var TileCoord = require('./tile_coord');
var Point = require('point-geometry');
var Cache = require('../util/lru_cache');
var Coordinate = require('../geo/coordinate');
var util = require('../util/util');
var EXTENT = require('../data/bucket').EXTENT;
module.exports = TilePyramid;
function TilePyramid(options) {
    this.tileSize = options.tileSize;
    this.minzoom = options.minzoom;
    this.maxzoom = options.maxzoom;
    this.roundZoom = options.roundZoom;
    this.reparseOverscaled = options.reparseOverscaled;
    this._load = options.load;
    this._abort = options.abort;
    this._unload = options.unload;
    this._add = options.add;
    this._remove = options.remove;
    this._redoPlacement = options.redoPlacement;
    this._tiles = {};
    this._cache = new Cache(0, function (tile) {
        return this._unload(tile);
    }.bind(this));
    this._isIdRenderable = this._isIdRenderable.bind(this);
}
TilePyramid.maxOverzooming = 10;
TilePyramid.maxUnderzooming = 3;
TilePyramid.prototype = {
    loaded: function () {
        for (var t in this._tiles) {
            var tile = this._tiles[t];
            if (tile.state !== 'loaded' && tile.state !== 'errored')
                return false;
        }
        return true;
    },
    getIds: function () {
        return Object.keys(this._tiles).map(Number).sort(compareKeyZoom);
    },
    getRenderableIds: function () {
        return this.getIds().filter(this._isIdRenderable);
    },
    _isIdRenderable: function (id) {
        return this._tiles[id].isRenderable() && !this._coveredTiles[id];
    },
    reload: function () {
        this._cache.reset();
        for (var i in this._tiles) {
            var tile = this._tiles[i];
            if (tile.state !== 'loading') {
                tile.state = 'reloading';
            }
            this._load(tile);
        }
    },
    getTile: function (id) {
        return this._tiles[id];
    },
    getZoom: function (transform) {
        return transform.zoom + Math.log(transform.tileSize / this.tileSize) / Math.LN2;
    },
    coveringZoomLevel: function (transform) {
        return (this.roundZoom ? Math.round : Math.floor)(this.getZoom(transform));
    },
    coveringTiles: function (transform) {
        var z = this.coveringZoomLevel(transform);
        var actualZ = z;
        if (z < this.minzoom)
            return [];
        if (z > this.maxzoom)
            z = this.maxzoom;
        var tr = transform, tileCenter = tr.locationCoordinate(tr.center)._zoomTo(z), centerPoint = new Point(tileCenter.column - 0.5, tileCenter.row - 0.5);
        return TileCoord.cover(z, [
            tr.pointCoordinate(new Point(0, 0))._zoomTo(z),
            tr.pointCoordinate(new Point(tr.width, 0))._zoomTo(z),
            tr.pointCoordinate(new Point(tr.width, tr.height))._zoomTo(z),
            tr.pointCoordinate(new Point(0, tr.height))._zoomTo(z)
        ], this.reparseOverscaled ? actualZ : z).sort(function (a, b) {
            return centerPoint.dist(a) - centerPoint.dist(b);
        });
    },
    findLoadedChildren: function (coord, maxCoveringZoom, retain) {
        var found = false;
        for (var id in this._tiles) {
            var tile = this._tiles[id];
            if (retain[id] || !tile.isRenderable() || tile.coord.z <= coord.z || tile.coord.z > maxCoveringZoom)
                continue;
            var z2 = Math.pow(2, Math.min(tile.coord.z, this.maxzoom) - Math.min(coord.z, this.maxzoom));
            if (Math.floor(tile.coord.x / z2) !== coord.x || Math.floor(tile.coord.y / z2) !== coord.y)
                continue;
            retain[id] = true;
            found = true;
            while (tile && tile.coord.z - 1 > coord.z) {
                var parentId = tile.coord.parent(this.maxzoom).id;
                tile = this._tiles[parentId];
                if (tile && tile.isRenderable()) {
                    delete retain[id];
                    retain[parentId] = true;
                }
            }
        }
        return found;
    },
    findLoadedParent: function (coord, minCoveringZoom, retain) {
        for (var z = coord.z - 1; z >= minCoveringZoom; z--) {
            coord = coord.parent(this.maxzoom);
            var tile = this._tiles[coord.id];
            if (tile && tile.isRenderable()) {
                retain[coord.id] = true;
                return tile;
            }
            if (this._cache.has(coord.id)) {
                this.addTile(coord);
                retain[coord.id] = true;
                return this._tiles[coord.id];
            }
        }
    },
    updateCacheSize: function (transform) {
        var widthInTiles = Math.ceil(transform.width / transform.tileSize) + 1;
        var heightInTiles = Math.ceil(transform.height / transform.tileSize) + 1;
        var approxTilesInView = widthInTiles * heightInTiles;
        var commonZoomRange = 5;
        this._cache.setMaxSize(Math.floor(approxTilesInView * commonZoomRange));
    },
    update: function (used, transform, fadeDuration) {
        var i;
        var coord;
        var tile;
        this.updateCacheSize(transform);
        var zoom = (this.roundZoom ? Math.round : Math.floor)(this.getZoom(transform));
        var minCoveringZoom = Math.max(zoom - TilePyramid.maxOverzooming, this.minzoom);
        var maxCoveringZoom = Math.max(zoom + TilePyramid.maxUnderzooming, this.minzoom);
        var retain = {};
        var now = new Date().getTime();
        this._coveredTiles = {};
        var required = used ? this.coveringTiles(transform) : [];
        for (i = 0; i < required.length; i++) {
            coord = required[i];
            tile = this.addTile(coord);
            retain[coord.id] = true;
            if (tile.isRenderable())
                continue;
            if (!this.findLoadedChildren(coord, maxCoveringZoom, retain)) {
                this.findLoadedParent(coord, minCoveringZoom, retain);
            }
        }
        var parentsForFading = {};
        var ids = Object.keys(retain);
        for (var k = 0; k < ids.length; k++) {
            var id = ids[k];
            coord = TileCoord.fromID(id);
            tile = this._tiles[id];
            if (tile && tile.timeAdded > now - (fadeDuration || 0)) {
                if (this.findLoadedChildren(coord, maxCoveringZoom, retain)) {
                    retain[id] = true;
                }
                this.findLoadedParent(coord, minCoveringZoom, parentsForFading);
            }
        }
        var fadedParent;
        for (fadedParent in parentsForFading) {
            if (!retain[fadedParent]) {
                this._coveredTiles[fadedParent] = true;
            }
        }
        for (fadedParent in parentsForFading) {
            retain[fadedParent] = true;
        }
        var remove = util.keysDifference(this._tiles, retain);
        for (i = 0; i < remove.length; i++) {
            this.removeTile(+remove[i]);
        }
        this.transform = transform;
    },
    addTile: function (coord) {
        var tile = this._tiles[coord.id];
        if (tile)
            return tile;
        var wrapped = coord.wrapped();
        tile = this._tiles[wrapped.id];
        if (!tile) {
            tile = this._cache.get(wrapped.id);
            if (tile && this._redoPlacement) {
                this._redoPlacement(tile);
            }
        }
        if (!tile) {
            var zoom = coord.z;
            var overscaling = zoom > this.maxzoom ? Math.pow(2, zoom - this.maxzoom) : 1;
            tile = new Tile(wrapped, this.tileSize * overscaling, this.maxzoom);
            this._load(tile);
        }
        tile.uses++;
        this._tiles[coord.id] = tile;
        this._add(tile, coord);
        return tile;
    },
    removeTile: function (id) {
        var tile = this._tiles[id];
        if (!tile)
            return;
        tile.uses--;
        delete this._tiles[id];
        this._remove(tile);
        if (tile.uses > 0)
            return;
        if (tile.isRenderable()) {
            this._cache.add(tile.coord.wrapped().id, tile);
        } else {
            this._abort(tile);
            this._unload(tile);
        }
    },
    clearTiles: function () {
        for (var id in this._tiles)
            this.removeTile(id);
        this._cache.reset();
    },
    tilesIn: function (queryGeometry) {
        var tileResults = {};
        var ids = this.getIds();
        var minX = Infinity;
        var minY = Infinity;
        var maxX = -Infinity;
        var maxY = -Infinity;
        var z = queryGeometry[0].zoom;
        for (var k = 0; k < queryGeometry.length; k++) {
            var p = queryGeometry[k];
            minX = Math.min(minX, p.column);
            minY = Math.min(minY, p.row);
            maxX = Math.max(maxX, p.column);
            maxY = Math.max(maxY, p.row);
        }
        for (var i = 0; i < ids.length; i++) {
            var tile = this._tiles[ids[i]];
            var coord = TileCoord.fromID(ids[i]);
            var tileSpaceBounds = [
                coordinateToTilePoint(coord, tile.sourceMaxZoom, new Coordinate(minX, minY, z)),
                coordinateToTilePoint(coord, tile.sourceMaxZoom, new Coordinate(maxX, maxY, z))
            ];
            if (tileSpaceBounds[0].x < EXTENT && tileSpaceBounds[0].y < EXTENT && tileSpaceBounds[1].x >= 0 && tileSpaceBounds[1].y >= 0) {
                var tileSpaceQueryGeometry = [];
                for (var j = 0; j < queryGeometry.length; j++) {
                    tileSpaceQueryGeometry.push(coordinateToTilePoint(coord, tile.sourceMaxZoom, queryGeometry[j]));
                }
                var tileResult = tileResults[tile.coord.id];
                if (tileResult === undefined) {
                    tileResult = tileResults[tile.coord.id] = {
                        tile: tile,
                        coord: coord,
                        queryGeometry: [],
                        scale: Math.pow(2, this.transform.zoom - tile.coord.z)
                    };
                }
                tileResult.queryGeometry.push(tileSpaceQueryGeometry);
            }
        }
        var results = [];
        for (var t in tileResults) {
            results.push(tileResults[t]);
        }
        return results;
    }
};
function coordinateToTilePoint(tileCoord, sourceMaxZoom, coord) {
    var zoomedCoord = coord.zoomTo(Math.min(tileCoord.z, sourceMaxZoom));
    return {
        x: (zoomedCoord.column - (tileCoord.x + tileCoord.w * Math.pow(2, tileCoord.z))) * EXTENT,
        y: (zoomedCoord.row - tileCoord.y) * EXTENT
    };
}
function compareKeyZoom(a, b) {
    return a % 32 - b % 32;
}