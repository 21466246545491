'use strict';
module.exports = AnimationLoop;
function AnimationLoop() {
    this.n = 0;
    this.times = [];
}
AnimationLoop.prototype.stopped = function () {
    this.times = this.times.filter(function (t) {
        return t.time >= new Date().getTime();
    });
    return !this.times.length;
};
AnimationLoop.prototype.set = function (t) {
    this.times.push({
        id: this.n,
        time: t + new Date().getTime()
    });
    return this.n++;
};
AnimationLoop.prototype.cancel = function (n) {
    this.times = this.times.filter(function (t) {
        return t.id !== n;
    });
};