'use strict';
var util = require('../util/util');
var ajax = require('../util/ajax');
var browser = require('../util/browser');
var TilePyramid = require('./tile_pyramid');
var normalizeURL = require('../util/mapbox').normalizeSourceURL;
var TileCoord = require('./tile_coord');
exports._loadTileJSON = function (options) {
    var loaded = function (err, tileJSON) {
        if (err) {
            this.fire('error', { error: err });
            return;
        }
        util.extend(this, util.pick(tileJSON, [
            'tiles',
            'minzoom',
            'maxzoom',
            'attribution'
        ]));
        if (tileJSON.vector_layers) {
            this.vectorLayers = tileJSON.vector_layers;
            this.vectorLayerIds = this.vectorLayers.map(function (layer) {
                return layer.id;
            });
        }
        this._pyramid = new TilePyramid({
            tileSize: this.tileSize,
            minzoom: this.minzoom,
            maxzoom: this.maxzoom,
            roundZoom: this.roundZoom,
            reparseOverscaled: this.reparseOverscaled,
            load: this._loadTile.bind(this),
            abort: this._abortTile.bind(this),
            unload: this._unloadTile.bind(this),
            add: this._addTile.bind(this),
            remove: this._removeTile.bind(this),
            redoPlacement: this._redoTilePlacement ? this._redoTilePlacement.bind(this) : undefined
        });
        this.fire('load');
    }.bind(this);
    if (options.url) {
        ajax.getJSON(normalizeURL(options.url), loaded);
    } else {
        browser.frame(loaded.bind(this, null, options));
    }
};
exports.redoPlacement = function () {
    if (!this._pyramid) {
        return;
    }
    var ids = this._pyramid.getIds();
    for (var i = 0; i < ids.length; i++) {
        var tile = this._pyramid.getTile(ids[i]);
        this._redoTilePlacement(tile);
    }
};
exports._getTile = function (coord) {
    return this._pyramid.getTile(coord.id);
};
exports._getVisibleCoordinates = function () {
    if (!this._pyramid)
        return [];
    else
        return this._pyramid.getRenderableIds().map(TileCoord.fromID);
};
function sortTilesIn(a, b) {
    var coordA = a.coord;
    var coordB = b.coord;
    return coordA.z - coordB.z || coordA.y - coordB.y || coordA.w - coordB.w || coordA.x - coordB.x;
}
function mergeRenderedFeatureLayers(tiles) {
    var result = tiles[0] || {};
    for (var i = 1; i < tiles.length; i++) {
        var tile = tiles[i];
        for (var layerID in tile) {
            var tileFeatures = tile[layerID];
            var resultFeatures = result[layerID];
            if (resultFeatures === undefined) {
                resultFeatures = result[layerID] = tileFeatures;
            } else {
                for (var f = 0; f < tileFeatures.length; f++) {
                    resultFeatures.push(tileFeatures[f]);
                }
            }
        }
    }
    return result;
}
exports._queryRenderedVectorFeatures = function (queryGeometry, params, zoom, bearing) {
    if (!this._pyramid || !this.map)
        return [];
    var tilesIn = this._pyramid.tilesIn(queryGeometry);
    tilesIn.sort(sortTilesIn);
    var styleLayers = this.map.style._layers;
    var renderedFeatureLayers = [];
    for (var r = 0; r < tilesIn.length; r++) {
        var tileIn = tilesIn[r];
        if (!tileIn.tile.featureIndex)
            continue;
        renderedFeatureLayers.push(tileIn.tile.featureIndex.query({
            queryGeometry: tileIn.queryGeometry,
            scale: tileIn.scale,
            tileSize: tileIn.tile.tileSize,
            bearing: bearing,
            params: params
        }, styleLayers));
    }
    return mergeRenderedFeatureLayers(renderedFeatureLayers);
};
exports._querySourceFeatures = function (params) {
    if (!this._pyramid) {
        return [];
    }
    var pyramid = this._pyramid;
    var tiles = pyramid.getRenderableIds().map(function (id) {
        return pyramid.getTile(id);
    });
    var result = [];
    var dataTiles = {};
    for (var i = 0; i < tiles.length; i++) {
        var tile = tiles[i];
        var dataID = new TileCoord(Math.min(tile.sourceMaxZoom, tile.coord.z), tile.coord.x, tile.coord.y, 0).id;
        if (!dataTiles[dataID]) {
            dataTiles[dataID] = true;
            tile.querySourceFeatures(result, params);
        }
    }
    return result;
};
exports.create = function (source) {
    var sources = {
        vector: require('./vector_tile_source'),
        raster: require('./raster_tile_source'),
        geojson: require('./geojson_source'),
        video: require('./video_source'),
        image: require('./image_source')
    };
    return exports.is(source) ? source : new sources[source.type](source);
};
exports.is = function (source) {
    var sources = {
        vector: require('./vector_tile_source'),
        raster: require('./raster_tile_source'),
        geojson: require('./geojson_source'),
        video: require('./video_source'),
        image: require('./image_source')
    };
    for (var type in sources) {
        if (source instanceof sources[type]) {
            return true;
        }
    }
    return false;
};