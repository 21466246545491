'use strict';
module.exports = DoubleClickZoomHandler;
function DoubleClickZoomHandler(map) {
    this._map = map;
    this._onDblClick = this._onDblClick.bind(this);
}
DoubleClickZoomHandler.prototype = {
    _enabled: false,
    isEnabled: function () {
        return this._enabled;
    },
    enable: function () {
        if (this.isEnabled())
            return;
        this._map.on('dblclick', this._onDblClick);
        this._enabled = true;
    },
    disable: function () {
        if (!this.isEnabled())
            return;
        this._map.off('dblclick', this._onDblClick);
        this._enabled = false;
    },
    _onDblClick: function (e) {
        this._map.zoomTo(this._map.getZoom() + (e.originalEvent.shiftKey ? -1 : 1), { around: e.lngLat }, e);
    }
};