'use strict';
module.exports = CollisionFeature;
function CollisionFeature(collisionBoxArray, line, anchor, featureIndex, sourceLayerIndex, bucketIndex, shaped, boxScale, padding, alignLine, straight) {
    var y1 = shaped.top * boxScale - padding;
    var y2 = shaped.bottom * boxScale + padding;
    var x1 = shaped.left * boxScale - padding;
    var x2 = shaped.right * boxScale + padding;
    this.boxStartIndex = collisionBoxArray.length;
    if (alignLine) {
        var height = y2 - y1;
        var length = x2 - x1;
        if (height > 0) {
            height = Math.max(10 * boxScale, height);
            if (straight) {
                var vector = line[anchor.segment + 1].sub(line[anchor.segment])._unit()._mult(length);
                var straightLine = [
                    anchor.sub(vector),
                    anchor.add(vector)
                ];
                this._addLineCollisionBoxes(collisionBoxArray, straightLine, anchor, 0, length, height, featureIndex, sourceLayerIndex, bucketIndex);
            } else {
                this._addLineCollisionBoxes(collisionBoxArray, line, anchor, anchor.segment, length, height, featureIndex, sourceLayerIndex, bucketIndex);
            }
        }
    } else {
        collisionBoxArray.emplaceBack(anchor.x, anchor.y, x1, y1, x2, y2, Infinity, featureIndex, sourceLayerIndex, bucketIndex, 0, 0, 0, 0, 0);
    }
    this.boxEndIndex = collisionBoxArray.length;
}
CollisionFeature.prototype._addLineCollisionBoxes = function (collisionBoxArray, line, anchor, segment, labelLength, boxSize, featureIndex, sourceLayerIndex, bucketIndex) {
    var step = boxSize / 2;
    var nBoxes = Math.floor(labelLength / step);
    var firstBoxOffset = -boxSize / 2;
    var bboxes = this.boxes;
    var p = anchor;
    var index = segment + 1;
    var anchorDistance = firstBoxOffset;
    do {
        index--;
        if (index < 0)
            return bboxes;
        anchorDistance -= line[index].dist(p);
        p = line[index];
    } while (anchorDistance > -labelLength / 2);
    var segmentLength = line[index].dist(line[index + 1]);
    for (var i = 0; i < nBoxes; i++) {
        var boxDistanceToAnchor = -labelLength / 2 + i * step;
        while (anchorDistance + segmentLength < boxDistanceToAnchor) {
            anchorDistance += segmentLength;
            index++;
            if (index + 1 >= line.length)
                return bboxes;
            segmentLength = line[index].dist(line[index + 1]);
        }
        var segmentBoxDistance = boxDistanceToAnchor - anchorDistance;
        var p0 = line[index];
        var p1 = line[index + 1];
        var boxAnchorPoint = p1.sub(p0)._unit()._mult(segmentBoxDistance)._add(p0)._round();
        var distanceToInnerEdge = Math.max(Math.abs(boxDistanceToAnchor - firstBoxOffset) - step / 2, 0);
        var maxScale = labelLength / 2 / distanceToInnerEdge;
        collisionBoxArray.emplaceBack(boxAnchorPoint.x, boxAnchorPoint.y, -boxSize / 2, -boxSize / 2, boxSize / 2, boxSize / 2, maxScale, featureIndex, sourceLayerIndex, bucketIndex, 0, 0, 0, 0, 0);
    }
    return bboxes;
};