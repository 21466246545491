'use strict';
module.exports = Buffer;
function Buffer(array, arrayType, type) {
    this.arrayBuffer = array.arrayBuffer;
    this.length = array.length;
    this.attributes = arrayType.members;
    this.itemSize = arrayType.bytesPerElement;
    this.type = type;
    this.arrayType = arrayType;
}
Buffer.prototype.bind = function (gl) {
    var type = gl[this.type];
    if (!this.buffer) {
        this.buffer = gl.createBuffer();
        gl.bindBuffer(type, this.buffer);
        gl.bufferData(type, this.arrayBuffer, gl.STATIC_DRAW);
        this.arrayBuffer = null;
    } else {
        gl.bindBuffer(type, this.buffer);
    }
};
var AttributeType = {
    Int8: 'BYTE',
    Uint8: 'UNSIGNED_BYTE',
    Int16: 'SHORT',
    Uint16: 'UNSIGNED_SHORT'
};
Buffer.prototype.setVertexAttribPointers = function (gl, program) {
    for (var j = 0; j < this.attributes.length; j++) {
        var member = this.attributes[j];
        var attribIndex = program[member.name];
        if (attribIndex !== undefined) {
            gl.vertexAttribPointer(attribIndex, member.components, gl[AttributeType[member.type]], false, this.arrayType.bytesPerElement, member.offset);
        }
    }
};
Buffer.prototype.destroy = function (gl) {
    if (this.buffer) {
        gl.deleteBuffer(this.buffer);
    }
};
Buffer.BufferType = {
    VERTEX: 'ARRAY_BUFFER',
    ELEMENT: 'ELEMENT_ARRAY_BUFFER'
};
Buffer.ELEMENT_ATTRIBUTE_TYPE = 'Uint16';
Buffer.VERTEX_ATTRIBUTE_ALIGNMENT = 4;