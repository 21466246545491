'use strict';
var util = require('../util/util');
var EXTENT = require('./bucket').EXTENT;
function createBounds(bits) {
    return {
        min: -1 * Math.pow(2, bits - 1),
        max: Math.pow(2, bits - 1) - 1
    };
}
var boundsLookup = {
    15: createBounds(15),
    16: createBounds(16)
};
module.exports = function loadGeometry(feature, bits) {
    var bounds = boundsLookup[bits || 16];
    var scale = EXTENT / feature.extent;
    var geometry = feature.loadGeometry();
    for (var r = 0; r < geometry.length; r++) {
        var ring = geometry[r];
        for (var p = 0; p < ring.length; p++) {
            var point = ring[p];
            point.x = Math.round(point.x * scale);
            point.y = Math.round(point.y * scale);
            if (point.x < bounds.min || point.x > bounds.max || point.y < bounds.min || point.y > bounds.max) {
                util.warnOnce('Geometry exceeds allowed extent, reduce your vector tile buffer size');
            }
        }
    }
    return geometry;
};