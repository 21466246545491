'use strict';
var util = require('./util');
var Evented = {
    on: function (type, listener) {
        this._events = this._events || {};
        this._events[type] = this._events[type] || [];
        this._events[type].push(listener);
        return this;
    },
    off: function (type, listener) {
        if (!type) {
            delete this._events;
            return this;
        }
        if (!this.listens(type))
            return this;
        if (listener) {
            var idx = this._events[type].indexOf(listener);
            if (idx >= 0) {
                this._events[type].splice(idx, 1);
            }
            if (!this._events[type].length) {
                delete this._events[type];
            }
        } else {
            delete this._events[type];
        }
        return this;
    },
    once: function (type, listener) {
        var wrapper = function (data) {
            this.off(type, wrapper);
            listener.call(this, data);
        }.bind(this);
        this.on(type, wrapper);
        return this;
    },
    fire: function (type, data) {
        if (!this.listens(type))
            return this;
        data = util.extend({}, data);
        util.extend(data, {
            type: type,
            target: this
        });
        var listeners = this._events[type].slice();
        for (var i = 0; i < listeners.length; i++) {
            listeners[i].call(this, data);
        }
        return this;
    },
    listens: function (type) {
        return !!(this._events && this._events[type]);
    }
};
module.exports = Evented;