'use strict';
var StructArrayType = require('../util/struct_array');
var util = require('../util/util');
var Point = require('point-geometry');
var SymbolQuad = require('./quads').SymbolQuad;
var SymbolQuadsArray = module.exports = new StructArrayType({
    members: [
        {
            type: 'Int16',
            name: 'anchorPointX'
        },
        {
            type: 'Int16',
            name: 'anchorPointY'
        },
        {
            type: 'Float32',
            name: 'tlX'
        },
        {
            type: 'Float32',
            name: 'tlY'
        },
        {
            type: 'Float32',
            name: 'trX'
        },
        {
            type: 'Float32',
            name: 'trY'
        },
        {
            type: 'Float32',
            name: 'blX'
        },
        {
            type: 'Float32',
            name: 'blY'
        },
        {
            type: 'Float32',
            name: 'brX'
        },
        {
            type: 'Float32',
            name: 'brY'
        },
        {
            type: 'Int16',
            name: 'texH'
        },
        {
            type: 'Int16',
            name: 'texW'
        },
        {
            type: 'Int16',
            name: 'texX'
        },
        {
            type: 'Int16',
            name: 'texY'
        },
        {
            type: 'Float32',
            name: 'anchorAngle'
        },
        {
            type: 'Float32',
            name: 'glyphAngle'
        },
        {
            type: 'Float32',
            name: 'maxScale'
        },
        {
            type: 'Float32',
            name: 'minScale'
        }
    ]
});
util.extendAll(SymbolQuadsArray.prototype.StructType.prototype, {
    get anchorPoint() {
        return new Point(this.anchorPointX, this.anchorPointY);
    },
    get SymbolQuad() {
        return new SymbolQuad(this.anchorPoint, new Point(this.tlX, this.tlY), new Point(this.trX, this.trY), new Point(this.blX, this.blY), new Point(this.brX, this.brY), {
            x: this.texX,
            y: this.texY,
            h: this.texH,
            w: this.texW,
            height: this.texH,
            width: this.texW
        }, this.anchorAngle, this.glyphAngle, this.minScale, this.maxScale);
    }
});