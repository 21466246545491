'use strict';
module.exports = LngLat;
var wrap = require('../util/util').wrap;
function LngLat(lng, lat) {
    if (isNaN(lng) || isNaN(lat)) {
        throw new Error('Invalid LngLat object: (' + lng + ', ' + lat + ')');
    }
    this.lng = +lng;
    this.lat = +lat;
    if (this.lat > 90 || this.lat < -90) {
        throw new Error('Invalid LngLat latitude value: must be between -90 and 90');
    }
}
LngLat.prototype.wrap = function () {
    return new LngLat(wrap(this.lng, -180, 180), this.lat);
};
LngLat.prototype.toArray = function () {
    return [
        this.lng,
        this.lat
    ];
};
LngLat.prototype.toString = function () {
    return 'LngLat(' + this.lng + ', ' + this.lat + ')';
};
LngLat.convert = function (input) {
    if (input instanceof LngLat) {
        return input;
    }
    if (Array.isArray(input)) {
        return new LngLat(input[0], input[1]);
    }
    return input;
};