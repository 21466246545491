'use strict';
module.exports = LngLatBounds;
var LngLat = require('./lng_lat');
function LngLatBounds(sw, ne) {
    if (!sw) {
        return;
    } else if (ne) {
        this.extend(sw).extend(ne);
    } else if (sw.length === 4) {
        this.extend([
            sw[0],
            sw[1]
        ]).extend([
            sw[2],
            sw[3]
        ]);
    } else {
        this.extend(sw[0]).extend(sw[1]);
    }
}
LngLatBounds.prototype = {
    extend: function (obj) {
        var sw = this._sw, ne = this._ne, sw2, ne2;
        if (obj instanceof LngLat) {
            sw2 = obj;
            ne2 = obj;
        } else if (obj instanceof LngLatBounds) {
            sw2 = obj._sw;
            ne2 = obj._ne;
            if (!sw2 || !ne2)
                return this;
        } else {
            return obj ? this.extend(LngLat.convert(obj) || LngLatBounds.convert(obj)) : this;
        }
        if (!sw && !ne) {
            this._sw = new LngLat(sw2.lng, sw2.lat);
            this._ne = new LngLat(ne2.lng, ne2.lat);
        } else {
            sw.lng = Math.min(sw2.lng, sw.lng);
            sw.lat = Math.min(sw2.lat, sw.lat);
            ne.lng = Math.max(ne2.lng, ne.lng);
            ne.lat = Math.max(ne2.lat, ne.lat);
        }
        return this;
    },
    getCenter: function () {
        return new LngLat((this._sw.lng + this._ne.lng) / 2, (this._sw.lat + this._ne.lat) / 2);
    },
    getSouthWest: function () {
        return this._sw;
    },
    getNorthEast: function () {
        return this._ne;
    },
    getNorthWest: function () {
        return new LngLat(this.getWest(), this.getNorth());
    },
    getSouthEast: function () {
        return new LngLat(this.getEast(), this.getSouth());
    },
    getWest: function () {
        return this._sw.lng;
    },
    getSouth: function () {
        return this._sw.lat;
    },
    getEast: function () {
        return this._ne.lng;
    },
    getNorth: function () {
        return this._ne.lat;
    },
    toArray: function () {
        return [
            this._sw.toArray(),
            this._ne.toArray()
        ];
    },
    toString: function () {
        return 'LngLatBounds(' + this._sw.toString() + ', ' + this._ne.toString() + ')';
    }
};
LngLatBounds.convert = function (input) {
    if (!input || input instanceof LngLatBounds)
        return input;
    return new LngLatBounds(input);
};