'use strict';
module.exports = StructArrayType;
var viewTypes = {
    'Int8': Int8Array,
    'Uint8': Uint8Array,
    'Uint8Clamped': Uint8ClampedArray,
    'Int16': Int16Array,
    'Uint16': Uint16Array,
    'Int32': Int32Array,
    'Uint32': Uint32Array,
    'Float32': Float32Array,
    'Float64': Float64Array
};
var structArrayTypeCache = {};
function StructArrayType(options) {
    var key = JSON.stringify(options);
    if (structArrayTypeCache[key]) {
        return structArrayTypeCache[key];
    }
    if (options.alignment === undefined)
        options.alignment = 1;
    function StructType() {
        Struct.apply(this, arguments);
    }
    StructType.prototype = Object.create(Struct.prototype);
    var offset = 0;
    var maxSize = 0;
    var usedTypes = ['Uint8'];
    StructType.prototype.members = options.members.map(function (member) {
        member = {
            name: member.name,
            type: member.type,
            components: member.components || 1
        };
        if (usedTypes.indexOf(member.type) < 0)
            usedTypes.push(member.type);
        var typeSize = sizeOf(member.type);
        maxSize = Math.max(maxSize, typeSize);
        member.offset = offset = align(offset, Math.max(options.alignment, typeSize));
        for (var c = 0; c < member.components; c++) {
            Object.defineProperty(StructType.prototype, member.name + (member.components === 1 ? '' : c), {
                get: createGetter(member, c),
                set: createSetter(member, c)
            });
        }
        offset += typeSize * member.components;
        return member;
    });
    StructType.prototype.alignment = options.alignment;
    StructType.prototype.size = align(offset, Math.max(maxSize, options.alignment));
    function StructArrayType() {
        StructArray.apply(this, arguments);
        this.members = StructType.prototype.members;
    }
    StructArrayType.serialize = serializeStructArrayType;
    StructArrayType.prototype = Object.create(StructArray.prototype);
    StructArrayType.prototype.StructType = StructType;
    StructArrayType.prototype.bytesPerElement = StructType.prototype.size;
    StructArrayType.prototype.emplaceBack = createEmplaceBack(StructType.prototype.members, StructType.prototype.size);
    StructArrayType.prototype._usedTypes = usedTypes;
    structArrayTypeCache[key] = StructArrayType;
    return StructArrayType;
}
function serializeStructArrayType() {
    return {
        members: this.prototype.StructType.prototype.members,
        alignment: this.prototype.StructType.prototype.alignment,
        bytesPerElement: this.prototype.bytesPerElement
    };
}
function align(offset, size) {
    return Math.ceil(offset / size) * size;
}
function sizeOf(type) {
    return viewTypes[type].BYTES_PER_ELEMENT;
}
function getArrayViewName(type) {
    return type.toLowerCase();
}
function createEmplaceBack(members, bytesPerElement) {
    var usedTypeSizes = [];
    var argNames = [];
    var body = '' + 'var i = this.length;\n' + 'this.resize(this.length + 1);\n';
    for (var m = 0; m < members.length; m++) {
        var member = members[m];
        var size = sizeOf(member.type);
        if (usedTypeSizes.indexOf(size) < 0) {
            usedTypeSizes.push(size);
            body += 'var o' + size.toFixed(0) + ' = i * ' + (bytesPerElement / size).toFixed(0) + ';\n';
        }
        for (var c = 0; c < member.components; c++) {
            var argName = 'v' + argNames.length;
            var index = 'o' + size.toFixed(0) + ' + ' + (member.offset / size + c).toFixed(0);
            body += 'this.' + getArrayViewName(member.type) + '[' + index + '] = ' + argName + ';\n';
            argNames.push(argName);
        }
    }
    body += 'return i;';
    return new Function(argNames, body);
}
function createMemberComponentString(member, component) {
    var elementOffset = 'this._pos' + sizeOf(member.type).toFixed(0);
    var componentOffset = (member.offset / sizeOf(member.type) + component).toFixed(0);
    var index = elementOffset + ' + ' + componentOffset;
    return 'this._structArray.' + getArrayViewName(member.type) + '[' + index + ']';
}
function createGetter(member, c) {
    return new Function([], 'return ' + createMemberComponentString(member, c) + ';');
}
function createSetter(member, c) {
    return new Function(['x'], createMemberComponentString(member, c) + ' = x;');
}
function Struct(structArray, index) {
    this._structArray = structArray;
    this._pos1 = index * this.size;
    this._pos2 = this._pos1 / 2;
    this._pos4 = this._pos1 / 4;
    this._pos8 = this._pos1 / 8;
}
function StructArray(serialized) {
    if (serialized !== undefined) {
        this.arrayBuffer = serialized.arrayBuffer;
        this.length = serialized.length;
        this.capacity = this.arrayBuffer.byteLength / this.bytesPerElement;
        this._refreshViews();
    } else {
        this.capacity = -1;
        this.resize(0);
    }
}
StructArray.prototype.DEFAULT_CAPACITY = 128;
StructArray.prototype.RESIZE_MULTIPLIER = 5;
StructArray.prototype.serialize = function () {
    this.trim();
    return {
        length: this.length,
        arrayBuffer: this.arrayBuffer
    };
};
StructArray.prototype.get = function (index) {
    return new this.StructType(this, index);
};
StructArray.prototype.trim = function () {
    if (this.length !== this.capacity) {
        this.capacity = this.length;
        this.arrayBuffer = this.arrayBuffer.slice(0, this.length * this.bytesPerElement);
        this._refreshViews();
    }
};
StructArray.prototype.resize = function (n) {
    this.length = n;
    if (n > this.capacity) {
        this.capacity = Math.max(n, Math.floor(this.capacity * this.RESIZE_MULTIPLIER), this.DEFAULT_CAPACITY);
        this.arrayBuffer = new ArrayBuffer(this.capacity * this.bytesPerElement);
        var oldUint8Array = this.uint8;
        this._refreshViews();
        if (oldUint8Array)
            this.uint8.set(oldUint8Array);
    }
};
StructArray.prototype._refreshViews = function () {
    for (var t = 0; t < this._usedTypes.length; t++) {
        var type = this._usedTypes[t];
        this[getArrayViewName(type)] = new viewTypes[type](this.arrayBuffer);
    }
};
StructArray.prototype.toArray = function (startIndex, endIndex) {
    var array = [];
    for (var i = startIndex; i < endIndex; i++) {
        var struct = this.get(i);
        array.push(struct);
    }
    return array;
};