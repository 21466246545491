'use strict';
var Control = require('./control');
var browser = require('../../util/browser');
var DOM = require('../../util/dom');
var util = require('../../util/util');
module.exports = Geolocate;
var geoOptions = {
    enableHighAccuracy: false,
    timeout: 6000
};
function Geolocate(options) {
    util.setOptions(this, options);
}
Geolocate.prototype = util.inherit(Control, {
    options: { position: 'top-right' },
    onAdd: function (map) {
        var className = 'mapboxgl-ctrl';
        var container = this._container = DOM.create('div', className + '-group', map.getContainer());
        if (!browser.supportsGeolocation)
            return container;
        this._container.addEventListener('contextmenu', this._onContextMenu.bind(this));
        this._geolocateButton = DOM.create('button', className + '-icon ' + className + '-geolocate', this._container);
        this._geolocateButton.addEventListener('click', this._onClickGeolocate.bind(this));
        return container;
    },
    _onContextMenu: function (e) {
        e.preventDefault();
    },
    _onClickGeolocate: function () {
        navigator.geolocation.getCurrentPosition(this._success.bind(this), this._error.bind(this), geoOptions);
        this._timeoutId = setTimeout(this._finish.bind(this), 10000);
    },
    _success: function (position) {
        this._map.jumpTo({
            center: [
                position.coords.longitude,
                position.coords.latitude
            ],
            zoom: 17,
            bearing: 0,
            pitch: 0
        });
        this.fire('geolocate', position);
        this._finish();
    },
    _error: function (error) {
        this.fire('error', error);
        this._finish();
    },
    _finish: function () {
        if (this._timeoutId) {
            clearTimeout(this._timeoutId);
        }
        this._timeoutId = undefined;
    }
});